import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../utils/customButton";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../contexts/userContext";
import { IconCalendar, IconBrandGoogleFilled } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../utils/footer";

export default function ThumbGeniePublic() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithProvider(provider);
      navigate("/app/thumb-genie");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const steps = [
    {
      label: "Select a Model",
      content:
        "Choose from your trained models, each tailored to a specific style or theme for your channel.",
    },
    {
      label: "Enter Your Prompt",
      content:
        "Describe the thumbnail you want to create. You can use the model's base prompt as a starting point.",
    },
    {
      label: "Add a Reference Image (Optional)",
      content:
        "You can use a YouTube video URL to add a reference image, guiding the AI to create similar styles or compositions.",
    },
    {
      label: "Adjust Realism Scale",
      content:
        "Choose between Realistic, Balanced Realistic, Balanced Creative, or Highly Creative to fine-tune your thumbnail's style.",
    },
    {
      label: "Generate and Review",
      content:
        "Click 'Generate' to create your thumbnail. Review the results and use them as-is or as inspiration for further refinement.",
    },
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom relative"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-4 sm:px-8 py-4 mx-auto">
        <a href="/">
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="h-6 sm:h-8 cursor-pointer"
          />
        </a>
      </header>

      <main className="text-center mt-8 sm:mt-12 w-full max-w-3xl mx-auto px-4">
        <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6">
          ThumbGenie
        </h1>
        <p className="mb-6 text-gray-300 text-sm sm:text-base">
          Create stunning thumbnails for your YouTube videos with our AI-powered
          Thumbnail Generator.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <CustomButton
            variant="contained"
            startIcon={<IconCalendar size={isMobile ? 18 : 20} />}
            onClick={() =>
              window.open(
                "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                "_blank"
              )
            }
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": { backgroundColor: theme.palette.primary.dark },
              fontSize: { xs: "0.875rem", sm: "1rem" },
              padding: { xs: "8px 16px", sm: "10px 20px" },
            }}
          >
            Book a Demo
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<IconBrandGoogleFilled size={isMobile ? 18 : 20} />}
            onClick={handleSignIn}
            sx={{
              backgroundColor: "#282828",
              "&:hover": { backgroundColor: "#383838" },
              fontSize: { xs: "0.875rem", sm: "1rem" },
              padding: { xs: "8px 16px", sm: "10px 20px" },
            }}
          >
            Sign in with Google
          </CustomButton>
        </div>
        <div className="mt-8 sm:mt-12 w-full max-w-3xl mx-auto">
          <div
            className="relative"
            style={{
              paddingBottom: "56.25%",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/wBPgsAMoebg?autoplay=1&mute=0&controls=1&rel=0&modestbranding=1"
              title="YouGenie Demo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Thumbnail Examples section */}
        <section className="mt-12 sm:mt-16 w-full max-w-3xl mx-auto">
          <h2 className="text-base sm:text-lg font-bold mb-6 sm:mb-8 font-custom text-gray-400">
            powered by YouGenie
          </h2>
          <Slider {...carouselSettings}>
            {[
              {
                src: "/images/mr-beast-1.jpg",
                channel: "MrBeast",
              },
              {
                src: "/images/jake-paul-1.jpg",
                channel: "Jake Paul",
              },
              {
                src: "/images/meditarsons-1.jpg",
                channel: "MeditarSons",
              },
              {
                src: "/images/mr-beast-2.png",
                channel: "MrBeast",
              },
              {
                src: "/images/jake-paul-2.png",
                channel: "Jake Paul",
              },
              {
                src: "/images/meditarsons-2.png",
                channel: "MeditarSons",
              },
            ].map((image, index) => (
              <div key={index} className="px-2">
                <img src={image.src} className="w-full h-auto rounded-lg" />
                <p className="mt-4 text-center text-gray-300 text-sm sm:text-base">
                  {image.channel}'s channel.
                </p>
              </div>
            ))}
          </Slider>
        </section>

        {/* How it Works section */}
        <section className="mt-12 sm:mt-16">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            How it Works
          </h2>
          <Stepper
            orientation="vertical"
            sx={{
              "& .MuiStepIcon-root": {
                color: "#282828",
                "&.Mui-active, &.Mui-completed": {
                  color: "#FF0000",
                },
              },
              "& .MuiStepIcon-text": {
                fill: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "0.75rem",
              },
              "& .MuiStepLabel-label": {
                fontFamily: "'YouTubeSansRegular', sans-serif",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              },
              "& .MuiStepContent-root": {
                borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            {steps.map((step, index) => (
              <Step active={true} key={step.label}>
                <StepLabel>
                  <span className="text-white font-custom">{step.label}</span>
                </StepLabel>
                <StepContent>
                  <p className="text-gray-300 mb-4 text-left font-custom text-sm sm:text-base">
                    {step.content}
                  </p>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </section>
      </main>
      <Footer />
    </div>
  );
}
