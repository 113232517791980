import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import { IconBold, IconItalic } from "@tabler/icons-react";

interface RichTextEditorProps {
  value: string;
  onChange: (html: string) => void;
  fontFamily: string;
}

export default function RichTextEditor({
  value,
  onChange,
  fontFamily,
}: RichTextEditorProps) {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
        bulletList: false,
        orderedList: false,
        listItem: false,
        code: false,
        codeBlock: false,
        horizontalRule: false,
        blockquote: false,
        hardBreak: false,
      }),
      TextStyle,
      FontFamily,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor
        .getHTML()
        .replace(/<p>/gi, "")
        .replace(/<\/p>/gi, "")
        .trim();
      onChange(html);
    },
    editorProps: {
      attributes: {
        class: "prose prose-invert max-w-none focus:outline-none min-h-[50px]",
      },
      handleKeyDown: (view, event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
  });

  React.useEffect(() => {
    if (editor && !value) {
      editor.chain().focus().toggleBold().run();
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="bg-[#1A1A1F] rounded-lg border border-[#282828] overflow-hidden">
      <div className="border-b border-[#282828] p-2 flex gap-2">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`p-2 rounded hover:bg-[#282828] ${
            editor.isActive("bold") ? "bg-[#282828]" : ""
          }`}
        >
          <IconBold size={20} className="text-white" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`p-2 rounded hover:bg-[#282828] ${
            editor.isActive("italic") ? "bg-[#282828]" : ""
          }`}
        >
          <IconItalic size={20} className="text-white" />
        </button>
      </div>
      <style>{`
        .ProseMirror {
          padding: 1rem;
          color: white;
          font-family: ${fontFamily}, sans-serif;
          min-height: 100px;
          white-space: pre-wrap !important;
          word-break: break-word;
        }
        .ProseMirror p {
          margin: 0;
          line-height: 1.5;
        }
        .ProseMirror p.is-editor-empty:first-child::before {
          color: #4B5563;
          content: attr(data-placeholder);
          float: left;
          height: 0;
          pointer-events: none;
        }
        .ProseMirror br {
          display: block;
          content: "";
          margin-top: 0.5em;
        }
      `}</style>
      <EditorContent editor={editor} />
    </div>
  );
}
