import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  Divider,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Tooltip,
  InputAdornment,
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getDocs, query, orderBy, where } from "firebase/firestore";
import { Model } from "../../../models/model";
import ModelDb from "../../../db/modelDb";
import CustomButton from "../../utils/customButton";
import Functions from "../../../callable/functions";
import GenerationDb from "../../../db/generationDb";
import { Generation } from "../../../models/generation";
import { IconRefresh, IconLink, IconArrowRight } from "@tabler/icons-react";
import { trackEvent } from "../../../utils/analytics";
import { useNavigate, useLocation } from "react-router-dom";
import CustomAlert from "../../utils/customAlert";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDropzone } from "react-dropzone";

const ThumbsScreen: React.FC = () => {
  const user = useContext(UserContext);
  const [models, setModels] = useState<Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");

  const [generating, setGenerating] = useState(false);
  const [generatedThumbnails, setGeneratedThumbnails] = useState<string[]>([]);
  const [generations, setGenerations] = useState<Generation[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [youtubeLink, setYoutubeLink] = useState<string>("");

  const [realismScale, setRealismScale] = useState<number>(3.5);

  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [tempImage, setTempImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 100,
    height: 56.25,
    x: 0,
    y: 0,
  });

  const [useBaseModel, setUseBaseModel] = useState<boolean>(
    models.length === 0
  );

  const handleFileSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 5242880) {
        // 5MB
        alert("File is too large. Maximum size is 5MB.");
        return;
      }
      handleImageProcess(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelect,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
    maxSize: 5242880, // 5MB
  });

  useEffect(() => {
    const fetchModels = async () => {
      if (user) {
        setLoading(true);
        try {
          const modelDb = new ModelDb();
          const modelsQuery = query(
            modelDb.collection({ userId: user.id, modelId: "" }),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(modelsQuery);
          const modelsData = querySnapshot.docs.map((doc) =>
            Model.fromFirestore(doc)
          );
          setModels(modelsData);

          // Check for modelId in the URL
          const params = new URLSearchParams(location.search);
          const modelId = params.get("modelId");
          if (modelId) {
            setSelectedModel(modelId);
            setUseBaseModel(false); // Ensure we're in custom models mode
            const selectedModelData = modelsData.find((m) => m.id === modelId);
            if (selectedModelData) {
              setPrompt(selectedModelData.basePrompt || "");
              fetchGenerations(modelId);
            }
          } else if (modelsData.length === 0) {
            // If no models and no modelId in URL, fetch base model generations
            setUseBaseModel(true);
            fetchGenerations("");
          }
        } catch (error) {
          console.error("Error fetching models:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchModels();
  }, [user, location]);

  const handleGenerateThumbnail = async () => {
    if (!user || (!selectedModel && !useBaseModel) || !prompt) return;
    setGenerating(true);
    setGeneratedThumbnails([]);
    setError(null);
    try {
      const functions = new Functions();
      const result = await functions.generateThumbnail({
        userId: user.id,
        modelIdentifier: useBaseModel
          ? "black-forest-labs/flux-1.1-pro"
          : models.find((m) => m.id === selectedModel)?.identifier || "",
        prompt: prompt,
        image: useBaseModel ? undefined : selectedImage || undefined,
        guidanceScale: useBaseModel ? undefined : realismScale,
      });
      if (result.data.success && result.data.outputUrls.length > 0) {
        setGeneratedThumbnails(result.data.outputUrls);
        setSelectedImage(null);
        fetchGenerations(selectedModel);
        trackEvent("thumbnail_generated");
      } else if (result.data.error === "INSUFFICIENT_CREDITS") {
        setError("insufficient_credits");
      } else {
        setError("generation_error");
      }
    } catch (error: any) {
      console.error("Error generating thumbnail:", error);
      setError("generation_error");
    } finally {
      setGenerating(false);
    }
  };

  const handleModelChange = async (event: SelectChangeEvent<string>) => {
    const selectedModelId = event.target.value as string;
    setSelectedModel(selectedModelId);
    const selectedModelData = models.find((m) => m.id === selectedModelId);
    setPrompt(selectedModelData?.basePrompt || "");

    // Fetch all generations when model changes
    fetchGenerations(selectedModelId);
  };

  const fetchGenerations = async (modelId: string) => {
    if (user) {
      try {
        const generationDb = new GenerationDb();
        const modelIdentifier = useBaseModel
          ? "black-forest-labs/flux-1.1-pro"
          : models.find((m) => m.id === modelId)?.identifier;

        if (modelIdentifier) {
          const generationsQuery = query(
            generationDb.collection({ userId: user.id, generationId: "" }),
            where("modelIdentifier", "==", modelIdentifier),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(generationsQuery);
          const generationsData = querySnapshot.docs.map((doc) =>
            Generation.fromFirestore(doc)
          );
          setGenerations(generationsData);
        }
      } catch (error) {
        console.error("Error fetching generations:", error);
      }
    }
  };

  const handleImageSelect = (imageUrl: string) => {
    setSelectedImage(imageUrl === selectedImage ? null : imageUrl);
  };

  const handleYoutubeLinkSubmit = () => {
    const videoId = extractYoutubeVideoId(youtubeLink);
    if (videoId) {
      const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
      setSelectedImage(thumbnailUrl);
      setYoutubeLink("");
    } else {
      // Handle invalid YouTube link
      console.error("Invalid YouTube link");
    }
  };

  const handleYoutubeLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setYoutubeLink(url);
    const videoId = extractYoutubeVideoId(url);
    if (videoId) {
      const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
      setSelectedImage(thumbnailUrl);
    }
  };

  const extractYoutubeVideoId = (url: string): string | null => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const handleRealismScaleChange = (newScale: number) => {
    setRealismScale(newScale);
  };

  const getModeLabel = (scale: number) => {
    switch (scale) {
      case 2:
        return "Realistic";
      case 2.5:
        return "Balanced Realistic";
      case 3:
        return "Balanced Creative";
      case 3.5:
        return "Highly Creative";
      default:
        return "Unknown";
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleGetCredits = () => {
    navigate("/app/thumb-genie/credits");
  };

  const handleImageProcess = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
          // Image is already 16:9, use it directly
          setSelectedImage(reader.result as string);
          setYoutubeLink("");
        } else {
          // Image needs cropping
          setTempImage(reader.result as string);
          setCropDialogOpen(true);
        }
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = () => {
    const image = document.createElement("img");
    image.src = tempImage!;

    const canvas = document.createElement("canvas");
    canvas.width = 1280; // Standard YouTube thumbnail width
    canvas.height = 720; // Standard YouTube thumbnail height

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    ctx.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");
    setSelectedImage(croppedImage);
    setCropDialogOpen(false);
    setTempImage(null);
  };

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">Thumbnail Generator</h4>
      </div>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />

      <div className="space-y-4">
        <div className="flex justify-between items-center mb-6">
          <ButtonGroup variant="outlined" fullWidth>
            <Button
              onClick={() => {
                setUseBaseModel(false);
                if (selectedModel) {
                  fetchGenerations(selectedModel);
                } else {
                  setGenerations([]);
                }
              }}
              variant={!useBaseModel ? "contained" : "outlined"}
              sx={{
                flex: 1,
                height: "48px",
                backgroundColor: !useBaseModel ? "#FF0000" : "transparent",
                "&:hover": {
                  backgroundColor: !useBaseModel
                    ? "#CC0000"
                    : "rgba(255, 0, 0, 0.1)",
                },
              }}
            >
              Custom Models
            </Button>
            <Button
              onClick={() => {
                setUseBaseModel(true);
                fetchGenerations("");
              }}
              variant={useBaseModel ? "contained" : "outlined"}
              sx={{
                flex: 1,
                height: "48px",
                backgroundColor: useBaseModel ? "#FF0000" : "transparent",
                "&:hover": {
                  backgroundColor: useBaseModel
                    ? "#CC0000"
                    : "rgba(255, 0, 0, 0.1)",
                },
              }}
            >
              Quick Start
            </Button>
          </ButtonGroup>
        </div>

        {useBaseModel ? (
          <>
            <div className="bg-gradient-to-r from-red-500/10 to-red-600/10 border border-red-500/20 rounded-lg p-4 mb-4">
              <div className="flex items-start space-x-4">
                <div className="flex-grow">
                  <h3 className="text-white font-bold mb-2">
                    Using YouGenie v0 (Basic Model)
                  </h3>
                  <p className="text-gray-400 text-sm">
                    This is our basic model for quick thumbnail generation. For
                    better results and more control, try training a custom model
                    with your channel's style.
                  </p>
                </div>
                <CustomButton
                  onClick={() => {
                    setUseBaseModel(false);
                    navigate("/app/thumb-genie/models");
                  }}
                >
                  Train Model
                </CustomButton>
              </div>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              multiline
              rows={4}
              placeholder="Enter your prompt here"
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "rgba(255, 255, 255, 0.7)" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#282828",
                  },
                  "&:hover fieldset": {
                    borderColor: "#484848",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF0000",
                  },
                },
              }}
            />
          </>
        ) : (
          <>
            <Select
              value={selectedModel}
              onChange={handleModelChange}
              displayEmpty
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#1E1E1E",
                    color: "white",
                    borderRadius: "8px",
                  },
                },
              }}
              sx={{
                color: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#282828",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#484848",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FF0000",
                },
                "& .MuiSelect-icon": {
                  color: "white",
                },
              }}
            >
              <MenuItem
                value=""
                disabled
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                Select a model
              </MenuItem>
              {models.map((model) => (
                <MenuItem
                  key={model.id}
                  value={model.id}
                  sx={{
                    borderBottom: "1px solid #282828",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <div>
                    <div className="font-bold">{model.name}</div>
                    <div className="text-sm text-gray-400">
                      {model.description}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              variant="outlined"
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              multiline
              rows={4}
              placeholder={
                models.find((m) => m.id === selectedModel)?.basePrompt ||
                "Enter your prompt here"
              }
              InputProps={{
                style: { color: "white" },
              }}
              InputLabelProps={{
                style: { color: "rgba(255, 255, 255, 0.7)" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#282828",
                  },
                  "&:hover fieldset": {
                    borderColor: "#484848",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF0000",
                  },
                },
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="flex flex-col space-y-2">
                  <p className="text-sm text-gray-400">
                    Reference Image (Optional)
                  </p>
                  <div className="flex flex-col space-y-2">
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Paste YouTube video URL for thumbnail reference"
                      value={youtubeLink}
                      onChange={handleYoutubeLinkChange}
                      InputProps={{
                        style: { color: "white" },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconLink size={20} className="text-gray-400" />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#282828",
                          },
                          "&:hover fieldset": {
                            borderColor: "#484848",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#FF0000",
                          },
                        },
                      }}
                    />

                    <div
                      {...getRootProps()}
                      className={`border-2 border-dashed border-[#282828] rounded-lg p-4 text-center cursor-pointer ${
                        isDragActive ? "bg-gray-700" : ""
                      }`}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="text-sm text-gray-400">
                          Drop the image here ...
                        </p>
                      ) : (
                        <p className="text-sm text-gray-400">
                          Or drag & drop an image here, or click to select (16:9
                          ratio required)
                        </p>
                      )}
                    </div>

                    {selectedImage && (
                      <div className="flex items-center space-x-2">
                        <img
                          src={selectedImage}
                          alt="Selected reference"
                          className="w-32 h-18 object-cover rounded"
                        />
                        <IconRefresh
                          className="cursor-pointer text-gray-400 hover:text-white"
                          onClick={() => {
                            setSelectedImage(null);
                            setYoutubeLink("");
                          }}
                          size={20}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="flex flex-col space-y-2">
                  <p className="text-sm text-gray-400">Realism Scale</p>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="realism scale button group"
                    fullWidth
                    sx={{
                      height: "56px", // Adjust this value to match your TextField height
                    }}
                  >
                    {[2, 2.5, 3, 3.5].map((scale) => (
                      <Button
                        key={scale}
                        onClick={() => handleRealismScaleChange(scale)}
                        variant={
                          realismScale === scale ? "contained" : "outlined"
                        }
                        sx={{
                          flex: 1,
                          height: "100%",
                        }}
                      >
                        {getModeLabel(scale)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </Grid>
            </Grid>
            <div className="flex items-center justify-between space-x-4">
              <p className="text-sm text-gray-400">
                Tip: Use a previously generated image or a YouTube thumbnail as
                a reference to guide the AI in creating similar styles or
                compositions.
              </p>
              <CustomButton
                onClick={handleGenerateThumbnail}
                disabled={!selectedModel || !prompt || generating}
              >
                {generating ? "Generating..." : "Generate"}
              </CustomButton>
            </div>
          </>
        )}

        {useBaseModel && (
          <div className="flex items-center justify-end space-x-4">
            <CustomButton
              onClick={handleGenerateThumbnail}
              disabled={!prompt || generating}
            >
              {generating ? "Generating..." : "Generate"}
            </CustomButton>
          </div>
        )}
      </div>
      {generations.length > 0 && (
        <div className="mt-8">
          <h4 className="text-base font-custom font-bold mb-4">
            All Generated Thumbnails
          </h4>
          <Grid container spacing={2}>
            {generations.map((generation, index) => (
              <Grid item xs={12} sm={6} md={3} key={generation.id}>
                <Tooltip
                  title={
                    <div>
                      <p className="font-custom">{generation.prompt}</p>
                    </div>
                  }
                  arrow
                  placement="top"
                >
                  <div
                    className={`aspect-w-16 aspect-h-9 cursor-pointer ${
                      generation.outputUrl === selectedImage
                        ? "ring-2 ring-red-500 rounded-lg"
                        : ""
                    }`}
                    onClick={() => handleImageSelect(generation.outputUrl)}
                  >
                    <img
                      src={generation.outputUrl}
                      alt={`Generated Thumbnail ${index + 1}`}
                      className="object-cover w-full h-full rounded-lg"
                    />
                  </div>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <CustomAlert
        open={error !== null}
        onClose={handleCloseError}
        severity="error"
        action={
          error === "insufficient_credits" && (
            <CustomButton onClick={handleGetCredits}>Get Credits</CustomButton>
          )
        }
      >
        {error === "insufficient_credits"
          ? "You don't have enough credits to generate thumbnails."
          : "An error occurred while generating the thumbnail."}
      </CustomAlert>
      <Dialog
        open={cropDialogOpen}
        onClose={() => setCropDialogOpen(false)}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#0B0B0F",
            color: "white",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "'YouTubeSansRegular', sans-serif",
            fontSize: "1rem",
            paddingBottom: 2,
          }}
        >
          Crop Image (16:9)
        </DialogTitle>
        <DialogContent>
          {tempImage && (
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              aspect={16 / 9}
              className="max-h-[70vh]"
            >
              <img src={tempImage} alt="Crop preview" />
            </ReactCrop>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setCropDialogOpen(false)}>
            Cancel
          </CustomButton>
          <CustomButton onClick={handleCropComplete}>Apply</CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThumbsScreen;
