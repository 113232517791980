import React from "react";

interface VideoPreviewProps {
  videoId: string;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ videoId }) => {
  return (
    <div className="bg-gray-800 p-2 rounded-lg">
      <iframe
        width="240"
        height="135"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPreview;
