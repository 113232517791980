import React, { useState, useEffect, useRef } from "react";
import { Slider, Switch, Collapse } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomButton from "../utils/customButton";
import {
  IconDownload,
  IconTypography,
  IconPhoto,
  IconClick,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import { HexColorPicker } from "react-colorful";
import RichTextEditor from "../utils/RichTextEditor";

const FONT_OPTIONS = [
  { label: "Arial", value: "Arial" },
  { label: "Impact", value: "Impact" },
  { label: "Helvetica", value: "Helvetica" },
  { label: "Verdana", value: "Verdana" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Georgia", value: "Georgia" },
  { label: "Courier New", value: "Courier New" },
  { label: "Tahoma", value: "Tahoma" },
  { label: "Comic Sans MS", value: "Comic Sans MS" },
];

export default function ThumbnailEditor() {
  // Image States
  const [image, setImage] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  // Text States
  const [textElements, setTextElements] = useState([
    {
      id: "1",
      text: "",
      fontSize: 48,
      textColor: "#FFFFFF",
      position: { x: 50, y: 50 },
      fontFamily: "Helvetica",
      textShadow: {
        enabled: false,
        blur: 4,
        color: "#000000",
      },
      textBackground: {
        enabled: false,
        color: "#000000",
        padding: 8,
        opacity: 0.5,
      },
      rotation: 0,
    },
  ]);

  // Logo States
  const [logo, setLogo] = useState<string | null>(null);
  const [logoPosition, setLogoPosition] = useState({ x: 90, y: 90 });
  const [logoSize, setLogoSize] = useState(100);
  const [logoOpacity, setLogoOpacity] = useState(1);

  const [openTools, setOpenTools] = useState({
    text: true,
    logo: true,
  });

  // Add this state for tracking the selected text element
  const [selectedTextId, setSelectedTextId] = useState<string>("1");

  // Add this helper function to get the selected text element
  const selectedText =
    textElements.find((te) => te.id === selectedTextId) || textElements[0];

  const handleFileSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 5242880) {
        alert("File is too large. Maximum size is 5MB.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 2097152) {
        alert("Logo file is too large. Maximum size is 2MB.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelect,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
    maxSize: 5242880,
  });

  const {
    getRootProps: getLogoRootProps,
    getInputProps: getLogoInputProps,
    isDragActive: isLogoDragActive,
  } = useDropzone({
    onDrop: handleLogoSelect,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: false,
    maxSize: 2097152,
  });

  const handleTextPositionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!textElements.length) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setTextElements((prev) =>
      prev.map((textElement) => ({
        ...textElement,
        position: { x, y },
      }))
    );
  };

  const parseHTML = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText
      .replace(/\n\s*\n/g, "\n")
      .replace(/\s+$/gm, "")
      .trim();
  };

  useEffect(() => {
    if (!image) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw main image
      ctx?.drawImage(img, 0, 0);

      // Draw each text element
      textElements.forEach((element) => {
        if (ctx && element.text) {
          ctx.save();
          ctx.font = `${
            element.text.includes("<strong>") ? "bold" : "normal"
          } ${element.text.includes("<em>") ? "italic" : "normal"} ${
            element.fontSize
          }px ${element.fontFamily}`;

          const x = (element.position.x / 100) * canvas.width;
          const y = (element.position.y / 100) * canvas.height;

          // Apply rotation
          ctx.translate(x, y);
          ctx.rotate((element.rotation * Math.PI) / 180);

          // Draw text background if enabled
          if (element.textBackground.enabled) {
            const metrics = ctx.measureText(element.text);
            const textHeight = element.fontSize;

            ctx.fillStyle = element.textBackground.color;
            ctx.globalAlpha = element.textBackground.opacity;
            ctx.fillRect(
              -element.textBackground.padding,
              -textHeight + element.textBackground.padding,
              metrics.width + element.textBackground.padding * 2,
              textHeight + element.textBackground.padding * 2
            );
            ctx.globalAlpha = 1;
          }

          // Draw text shadow
          if (element.textShadow.enabled) {
            ctx.shadowBlur = element.textShadow.blur;
            ctx.shadowColor = element.textShadow.color;
          }

          ctx.fillStyle = element.textColor;
          ctx.fillText(element.text, 0, 0);
          ctx.restore();
        }
      });

      // Draw logo if exists
      if (ctx && logo) {
        const logoImg = new Image();
        logoImg.onload = () => {
          const x = (logoPosition.x / 100) * canvas.width;
          const y = (logoPosition.y / 100) * canvas.height;

          ctx.globalAlpha = logoOpacity;
          ctx.drawImage(
            logoImg,
            x - logoSize / 2,
            y - logoSize / 2,
            logoSize,
            logoSize
          );
          ctx.globalAlpha = 1;

          setPreviewImage(canvas.toDataURL("image/png"));
        };
        logoImg.src = logo;
      } else {
        setPreviewImage(canvas.toDataURL("image/png"));
      }
    };
    img.src = image;
  }, [image, textElements, logo, logoPosition, logoSize, logoOpacity]);

  const handleDownload = () => {
    if (!previewImage) return;
    const link = document.createElement("a");
    link.download = "edited-thumbnail.png";
    link.href = previewImage;
    link.click();
  };

  const addTextElement = () => {
    setTextElements((prev) => [
      ...prev,
      {
        id: String(prev.length + 1),
        text: "",
        fontSize: 48,
        textColor: "#FFFFFF",
        position: { x: 50, y: 50 },
        fontFamily: "Helvetica",
        textShadow: {
          enabled: false,
          blur: 4,
          color: "#000000",
        },
        textBackground: {
          enabled: false,
          color: "#000000",
          padding: 8,
          opacity: 0.5,
        },
        rotation: 0,
      },
    ]);
  };

  const removeTextElement = (id: string) => {
    setTextElements((prev) => prev.filter((element) => element.id !== id));
  };

  const canvasRef = useRef<HTMLDivElement>(null);

  const handleCanvasClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    // Only update the position of the selected text element
    setTextElements((prev) =>
      prev.map((element) =>
        element.id === selectedTextId
          ? { ...element, position: { x, y } }
          : element
      )
    );
  };

  return (
    <div className="min-h-screen bg-[#0B0B0F] text-white font-custom">
      <header className="w-full max-w-5xl flex justify-between items-center px-4 py-4 mx-auto">
        <a href="/">
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="h-6 sm:h-8 cursor-pointer"
          />
        </a>
      </header>

      {/* Add Marketing Banner */}
      <div className="bg-gradient-to-r from-red-500 to-red-600 text-white">
        <div className="max-w-5xl mx-auto px-4 py-3 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="text-sm sm:text-base">
              Want AI-powered thumbnail generation? Try ThumbGenie for free!
            </span>
          </div>
          <a
            href="/thumb-genie"
            className="text-sm bg-white text-red-600 px-4 py-1 rounded-full font-semibold hover:bg-gray-100 transition-colors duration-200"
          >
            Try it!
          </a>
        </div>
      </div>

      <main className="max-w-5xl mx-auto px-4 py-8">
        <h1 className="text-2xl sm:text-4xl font-bold mb-4">
          YouTube Thumbnail Editor
        </h1>
        <p className="text-gray-400 mb-8">
          Free online thumbnail editor for YouTube creators. Add custom text
          with effects, overlay logos, and enhance your video thumbnails to
          increase click-through rates.
        </p>

        {/* New How It Works Section */}
        <div className="mb-12">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">How It Works</h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-[#1A1A1F] p-6 rounded-lg">
              <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                <span className="text-xl font-bold">1</span>
              </div>
              <h3 className="font-bold mb-2">Upload Your Thumbnail</h3>
              <p className="text-gray-400 text-sm">
                Start by uploading your base thumbnail image. Supports JPG and
                PNG formats up to 5MB.
              </p>
            </div>

            <div className="bg-[#1A1A1F] p-6 rounded-lg">
              <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                <span className="text-xl font-bold">2</span>
              </div>
              <h3 className="font-bold mb-2">Add Custom Text</h3>
              <p className="text-gray-400 text-sm">
                Customize your text with different fonts, sizes, colors, and
                effects.
              </p>
            </div>

            <div className="bg-[#1A1A1F] p-6 rounded-lg">
              <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                <span className="text-xl font-bold">3</span>
              </div>
              <h3 className="font-bold mb-2">Insert Your Logo</h3>
              <p className="text-gray-400 text-sm">
                Overlay your channel logo or watermark. Adjust size, position,
                and opacity.
              </p>
            </div>

            <div className="bg-[#1A1A1F] p-6 rounded-lg">
              <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                <span className="text-xl font-bold">4</span>
              </div>
              <h3 className="font-bold mb-2">Download & Use</h3>
              <p className="text-gray-400 text-sm">
                Preview your changes in real-time and download your edited
                thumbnail when ready to use.
              </p>
            </div>
          </div>
        </div>

        {/* Upload Section */}
        {!image && (
          <div
            {...getRootProps()}
            className={`border-2 border-dashed border-[#282828] rounded-lg p-12 text-center cursor-pointer mb-8 ${
              isDragActive ? "bg-gray-700" : ""
            }`}
          >
            <input {...getInputProps()} />
            <div className="space-y-4">
              <div className="flex justify-center">
                <IconPhoto size={48} className="text-gray-400" />
              </div>
              <div>
                <p className="text-lg text-gray-300 font-bold mb-2">
                  Upload your thumbnail
                </p>
                <p className="text-sm text-gray-400">
                  Drag & drop an image here, or click to select
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Editor Section */}
        {image && (
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Fixed Preview Section */}
            <div className="lg:w-7/12">
              <div className="sticky top-8">
                <div
                  ref={canvasRef}
                  className="relative w-full aspect-video bg-black rounded-lg overflow-hidden cursor-pointer"
                  onClick={handleCanvasClick}
                >
                  {/* Preview Image */}
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Preview"
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  )}

                  {/* Text Element Position Indicators */}
                  {textElements.map((element) => (
                    <div
                      key={element.id}
                      className={`absolute w-2 h-2 rounded-full transform -translate-x-1 -translate-y-1 ${
                        element.id === selectedTextId
                          ? "bg-red-500"
                          : "bg-white"
                      }`}
                      style={{
                        left: `${element.position.x}%`,
                        top: `${element.position.y}%`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedTextId(element.id);
                      }}
                    />
                  ))}
                </div>

                <div className="flex justify-between items-center">
                  <CustomButton
                    onClick={() => setImage(null)}
                    startIcon={<IconPhoto size={20} />}
                    variant="outlined"
                  >
                    Change
                  </CustomButton>

                  <CustomButton
                    onClick={handleDownload}
                    disabled={!image}
                    startIcon={<IconDownload size={20} />}
                  >
                    Download
                  </CustomButton>
                </div>
              </div>
            </div>

            {/* Tools Section */}
            <div className="lg:w-5/12 space-y-6">
              {/* Text Overlay Tool */}
              <div className="bg-[#1A1A1F] rounded-lg">
                <button
                  onClick={() =>
                    setOpenTools((prev) => ({ ...prev, text: !prev.text }))
                  }
                  className="w-full p-6 flex items-center justify-between"
                >
                  <div className="flex items-center gap-2">
                    <IconTypography size={20} className="text-gray-400" />
                    <h2 className="text-lg font-bold">Text Overlay</h2>
                  </div>
                  {openTools.text ? (
                    <IconChevronUp size={20} className="text-gray-400" />
                  ) : (
                    <IconChevronDown size={20} className="text-gray-400" />
                  )}
                </button>
                <Collapse in={openTools.text}>
                  <div className="px-6 pb-6">
                    {/* Add Text Element Button */}
                    <div className="mb-4 flex justify-between items-center">
                      <button
                        onClick={addTextElement}
                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors duration-200"
                      >
                        Add Text
                      </button>
                    </div>

                    {textElements.map((textElement, index) => (
                      <div
                        key={textElement.id}
                        className={`mb-6 relative ${
                          selectedTextId === textElement.id
                            ? "ring-2 ring-red-500"
                            : ""
                        }`}
                        onClick={() => setSelectedTextId(textElement.id)}
                      >
                        {/* Remove Text Element Button */}
                        {textElements.length > 1 && (
                          <button
                            onClick={() => removeTextElement(textElement.id)}
                            className="absolute -top-2 -right-2 bg-red-600 text-white w-6 h-6 rounded-full flex items-center justify-center hover:bg-red-700 transition-colors duration-200"
                          >
                            ×
                          </button>
                        )}

                        <RichTextEditor
                          value={textElement.text}
                          onChange={(text) =>
                            setTextElements((prev) =>
                              prev.map((te) =>
                                te.id === textElement.id ? { ...te, text } : te
                              )
                            )
                          }
                          fontFamily={textElement.fontFamily}
                        />
                      </div>
                    ))}

                    <div>
                      <p className="text-sm text-gray-400 mb-2">Font</p>
                      <select
                        value={selectedText.fontFamily}
                        onChange={(e) =>
                          setTextElements((prev) =>
                            prev.map((te) =>
                              te.id === selectedText.id
                                ? { ...te, fontFamily: e.target.value }
                                : te
                            )
                          )
                        }
                        className="w-full bg-[#1A1A1F] border border-gray-600 rounded px-3 py-2 text-white font-custom"
                      >
                        {FONT_OPTIONS.map((font) => (
                          <option
                            key={font.value}
                            value={font.value}
                            style={{ fontFamily: font.value }}
                          >
                            {font.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mt-6">
                      <p className="text-sm text-gray-400 mb-2">Font Size</p>
                      <Slider
                        value={selectedText.fontSize}
                        onChange={(_, value) =>
                          setTextElements((prev) =>
                            prev.map((te) => ({
                              ...te,
                              fontSize: value as number,
                            }))
                          )
                        }
                        min={12}
                        max={120}
                        valueLabelDisplay="auto"
                      />
                    </div>

                    <div>
                      <p className="text-sm text-gray-400 mb-2">Text Color</p>
                      <div className="w-full max-w-[200px]">
                        <HexColorPicker
                          color={selectedText.textColor}
                          onChange={(color) =>
                            setTextElements((prev) =>
                              prev.map((te) => ({
                                ...te,
                                textColor: color,
                              }))
                            )
                          }
                        />
                      </div>
                      <div className="flex items-center mt-2">
                        <div
                          className="w-6 h-6 rounded border border-gray-600 mr-2"
                          style={{ backgroundColor: selectedText.textColor }}
                        />
                        <input
                          type="text"
                          value={selectedText.textColor}
                          onChange={(e) =>
                            setTextElements((prev) =>
                              prev.map((te) => ({
                                ...te,
                                textColor: e.target.value,
                              }))
                            )
                          }
                          className="bg-[#1A1A1F] border border-gray-600 rounded px-2 py-1 text-sm w-24"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm text-gray-400">Text Shadow</p>
                        <Switch
                          checked={selectedText.textShadow.enabled}
                          onChange={(e) =>
                            setTextElements((prev) =>
                              prev.map((te) => ({
                                ...te,
                                textShadow: {
                                  ...te.textShadow,
                                  enabled: e.target.checked,
                                },
                              }))
                            )
                          }
                        />
                      </div>
                      {selectedText.textShadow.enabled && (
                        <div className="space-y-4">
                          <div>
                            <p className="text-xs text-gray-500 mb-1">
                              Shadow Blur
                            </p>
                            <Slider
                              value={selectedText.textShadow.blur}
                              onChange={(_, value) =>
                                setTextElements((prev) =>
                                  prev.map((te) => ({
                                    ...te,
                                    textShadow: {
                                      ...te.textShadow,
                                      blur: value as number,
                                    },
                                  }))
                                )
                              }
                              min={0}
                              max={20}
                              valueLabelDisplay="auto"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-gray-500 mb-1">
                              Shadow Color
                            </p>
                            <div className="w-full max-w-[200px]">
                              <HexColorPicker
                                color={selectedText.textShadow.color}
                                onChange={(color) =>
                                  setTextElements((prev) =>
                                    prev.map((te) => ({
                                      ...te,
                                      textShadow: {
                                        ...te.textShadow,
                                        color,
                                      },
                                    }))
                                  )
                                }
                              />
                            </div>
                            <div className="flex items-center mt-2">
                              <div
                                className="w-6 h-6 rounded border border-gray-600 mr-2"
                                style={{
                                  backgroundColor:
                                    selectedText.textShadow.color,
                                }}
                              />
                              <input
                                type="text"
                                value={selectedText.textShadow.color}
                                onChange={(e) =>
                                  setTextElements((prev) =>
                                    prev.map((te) => ({
                                      ...te,
                                      textShadow: {
                                        ...te.textShadow,
                                        color: e.target.value,
                                      },
                                    }))
                                  )
                                }
                                className="bg-[#1A1A1F] border border-gray-600 rounded px-2 py-1 text-sm w-24"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm text-gray-400">Text Background</p>
                        <Switch
                          checked={selectedText.textBackground.enabled}
                          onChange={(e) =>
                            setTextElements((prev) =>
                              prev.map((te) => ({
                                ...te,
                                textBackground: {
                                  ...te.textBackground,
                                  enabled: e.target.checked,
                                },
                              }))
                            )
                          }
                        />
                      </div>
                      {selectedText.textBackground.enabled && (
                        <div className="space-y-4">
                          <div>
                            <p className="text-xs text-gray-500 mb-1">
                              Background Color
                            </p>
                            <div className="w-full max-w-[200px]">
                              <HexColorPicker
                                color={selectedText.textBackground.color}
                                onChange={(color) =>
                                  setTextElements((prev) =>
                                    prev.map((te) => ({
                                      ...te,
                                      textBackground: {
                                        ...te.textBackground,
                                        color,
                                      },
                                    }))
                                  )
                                }
                              />
                            </div>
                            <div className="flex items-center mt-2">
                              <div
                                className="w-6 h-6 rounded border border-gray-600 mr-2"
                                style={{
                                  backgroundColor:
                                    selectedText.textBackground.color,
                                }}
                              />
                              <input
                                type="text"
                                value={selectedText.textBackground.color}
                                onChange={(e) =>
                                  setTextElements((prev) =>
                                    prev.map((te) => ({
                                      ...te,
                                      textBackground: {
                                        ...te.textBackground,
                                        color: e.target.value,
                                      },
                                    }))
                                  )
                                }
                                className="bg-[#1A1A1F] border border-gray-600 rounded px-2 py-1 text-sm w-24"
                              />
                            </div>
                          </div>
                          <div>
                            <p className="text-xs text-gray-500 mb-1">
                              Opacity
                            </p>
                            <Slider
                              value={selectedText.textBackground.opacity}
                              onChange={(_, value) =>
                                setTextElements((prev) =>
                                  prev.map((te) => ({
                                    ...te,
                                    textBackground: {
                                      ...te.textBackground,
                                      opacity: value as number,
                                    },
                                  }))
                                )
                              }
                              min={0}
                              max={1}
                              step={0.1}
                              valueLabelDisplay="auto"
                              valueLabelFormat={(value) =>
                                `${Math.round(value * 100)}%`
                              }
                            />
                          </div>
                          <div>
                            <p className="text-xs text-gray-500 mb-1">
                              Padding
                            </p>
                            <Slider
                              value={selectedText.textBackground.padding}
                              onChange={(_, value) =>
                                setTextElements((prev) =>
                                  prev.map((te) => ({
                                    ...te,
                                    textBackground: {
                                      ...te.textBackground,
                                      padding: value as number,
                                    },
                                  }))
                                )
                              }
                              min={0}
                              max={32}
                              valueLabelDisplay="auto"
                              valueLabelFormat={(value) => `${value}px`}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="text-sm text-gray-400 mb-2">
                        Text Rotation
                      </p>
                      <Slider
                        value={selectedText.rotation}
                        onChange={(_, value) =>
                          setTextElements((prev) =>
                            prev.map((te) => ({
                              ...te,
                              rotation: value as number,
                            }))
                          )
                        }
                        min={-180}
                        max={180}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}°`}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* Logo Overlay Tool */}
              <div className="bg-[#1A1A1F] rounded-lg">
                <button
                  onClick={() =>
                    setOpenTools((prev) => ({ ...prev, logo: !prev.logo }))
                  }
                  className="w-full p-6 flex items-center justify-between"
                >
                  <div className="flex items-center gap-2">
                    <IconPhoto size={20} className="text-gray-400" />
                    <h2 className="text-lg font-bold">Logo Overlay</h2>
                  </div>
                  {openTools.logo ? (
                    <IconChevronUp size={20} className="text-gray-400" />
                  ) : (
                    <IconChevronDown size={20} className="text-gray-400" />
                  )}
                </button>
                <Collapse in={openTools.logo}>
                  <div className="px-6 pb-6">
                    <div
                      {...getLogoRootProps()}
                      className={`border-2 border-dashed border-[#282828] rounded-lg p-4 text-center cursor-pointer ${
                        isLogoDragActive ? "bg-gray-700" : ""
                      }`}
                    >
                      <input {...getLogoInputProps()} />
                      {!logo ? (
                        isLogoDragActive ? (
                          <p className="text-sm text-gray-400">
                            Drop the logo here ...
                          </p>
                        ) : (
                          <p className="text-sm text-gray-400">
                            Drag & drop your logo here, or click to select
                          </p>
                        )
                      ) : (
                        <div className="relative w-32 h-32 mx-auto">
                          <img
                            src={logo}
                            alt="Logo preview"
                            className="w-full h-full object-contain"
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setLogo(null);
                            }}
                            className="absolute -top-2 -right-2 bg-red-500 rounded-full p-1 hover:bg-red-600"
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </div>

                    {logo && (
                      <>
                        <div>
                          <p className="text-sm text-gray-400 mb-2">
                            Logo Size
                          </p>
                          <Slider
                            value={logoSize}
                            onChange={(_, value) =>
                              setLogoSize(value as number)
                            }
                            min={20}
                            max={300}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}px`}
                          />
                        </div>

                        <div>
                          <p className="text-sm text-gray-400 mb-2">
                            Logo Opacity
                          </p>
                          <Slider
                            value={logoOpacity}
                            onChange={(_, value) =>
                              setLogoOpacity(value as number)
                            }
                            min={0}
                            max={1}
                            step={0.1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) =>
                              `${Math.round(value * 100)}%`
                            }
                          />
                        </div>

                        <div>
                          <p className="text-sm text-gray-400 mb-2">
                            Logo Position
                          </p>
                          <div className="grid grid-cols-3 gap-2">
                            {[
                              { x: 10, y: 10, label: "Top Left" },
                              { x: 50, y: 10, label: "Top Center" },
                              { x: 90, y: 10, label: "Top Right" },
                              { x: 10, y: 50, label: "Middle Left" },
                              { x: 50, y: 50, label: "Center" },
                              { x: 90, y: 50, label: "Middle Right" },
                              { x: 10, y: 90, label: "Bottom Left" },
                              { x: 50, y: 90, label: "Bottom Center" },
                              { x: 90, y: 90, label: "Bottom Right" },
                            ].map((pos) => (
                              <button
                                key={`${pos.x}-${pos.y}`}
                                onClick={() =>
                                  setLogoPosition({ x: pos.x, y: pos.y })
                                }
                                className={`p-2 rounded ${
                                  logoPosition.x === pos.x &&
                                  logoPosition.y === pos.y
                                    ? "bg-red-500"
                                    : "bg-gray-700 hover:bg-gray-600"
                                }`}
                              >
                                <span className="text-xs">{pos.label}</span>
                              </button>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
