import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import { Training, TrainingStatus } from "../../../models/training";
import {
  getDocs,
  query,
  orderBy,
  addDoc,
  collection,
  updateDoc,
} from "firebase/firestore";
import {
  CircularProgress,
  Typography,
  Tooltip,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { trackEvent } from "../../../utils/analytics";
import TrainingDb from "../../../db/trainingDb";
import moment from "moment";
import { db } from "../../../firebase";
import {
  IconDownload,
  IconCheck,
  IconX,
  IconClock,
  IconLoader,
  IconHelpCircle,
} from "@tabler/icons-react";
import CustomButton from "../../utils/customButton";
import JSZip from "jszip";
import { useNavigate } from "react-router-dom";

const ModelsScreen: React.FC = () => {
  const user = useContext(UserContext);
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTrainingName, setNewTrainingName] = useState("");
  const [newTrainingDescription, setNewTrainingDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [showHelp, setShowHelp] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrainings();
  }, [user]);

  const fetchTrainings = async () => {
    if (user) {
      setLoading(true);
      try {
        const trainingDb = new TrainingDb();
        const trainingsQuery = query(
          trainingDb.collection({ userId: user.id, trainingId: "" }),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(trainingsQuery);
        const trainingsData = querySnapshot.docs.map((doc) =>
          Training.fromFirestore(doc)
        );
        setTrainings(trainingsData);
      } catch (error) {
        console.error("Error fetching trainings:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileSelect = (acceptedFiles: File[]) => {
    if (acceptedFiles.length < 10) {
      alert("Please select at least 10 images for better training results.");
      return;
    }
    setSelectedFiles(acceptedFiles);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewTrainingName("");
    setNewTrainingDescription("");
    setSelectedFiles([]);
  };

  const handleStartTraining = async () => {
    if (user && selectedFiles.length > 0) {
      setUploading(true);
      try {
        const newTrainingRef = await addDoc(
          collection(db, `users/${user.id}/trainings`),
          {
            name: newTrainingName,
            description: newTrainingDescription,
            status: TrainingStatus.PENDING,
            createdAt: moment().toDate(),
            updatedAt: moment().toDate(),
          }
        );

        const zip = new JSZip();

        for (const file of selectedFiles) {
          const fileContent = await file.arrayBuffer();
          zip.file(file.name, fileContent);
        }

        const zipContent = await zip.generateAsync({ type: "blob" });

        const storage = getStorage();
        const storageRef = ref(
          storage,
          `users/${user.id}/trainings/${newTrainingRef.id}.zip`
        );
        await uploadBytes(storageRef, zipContent);

        const publicLink = await getDownloadURL(storageRef);

        await updateDoc(newTrainingRef, { publicLink });

        fetchTrainings();
        trackEvent("model_training_started");
        handleCloseDialog();
      } catch (error) {
        console.error("Error uploading images:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelect,
    accept: { "image/*": [".png", ".jpg", ".jpeg"] },
    multiple: true,
    minSize: 1,
  });

  const getStatusIcon = (status: TrainingStatus) => {
    switch (status) {
      case TrainingStatus.COMPLETED:
        return <IconCheck size={20} className="text-green-500" />;
      case TrainingStatus.FAILED:
        return <IconX size={20} className="text-red-500" />;
      case TrainingStatus.IN_PROGRESS:
        return <IconLoader size={20} className="text-blue-500 animate-spin" />;
      default:
        return <IconClock size={20} className="text-yellow-500" />;
    }
  };

  const getStatusMessage = (
    status: TrainingStatus,
    modelId: string,
    errorMessage?: string
  ) => {
    if (status === TrainingStatus.PENDING) {
      return (
        <span className="text-sm text-yellow-500 ml-2">
          Your training is queued and will be complete in a few hours. Don't
          worry, we will notify you via email when your model is ready to use.
        </span>
      );
    } else if (status === TrainingStatus.IN_PROGRESS) {
      return (
        <span className="text-sm text-blue-500 ml-2">
          Your model is currently being trained. This process typically takes a
          few hours. We'll notify you via email once it's ready.
        </span>
      );
    } else if (status === TrainingStatus.COMPLETED) {
      return (
        <span className="text-sm text-green-500 ml-2">
          Your model is ready.
        </span>
      );
    } else if (status === TrainingStatus.FAILED) {
      return (
        <span className="text-sm text-red-500 ml-2">
          Training failed: {errorMessage || "Unknown error"}
        </span>
      );
    }
    return null;
  };

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-4 md:px-32 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">Model Trainings</h4>
      </div>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />
      <div
        {...getRootProps()}
        className={`border-2 border-dashed border-[#282828] rounded-lg p-8 mb-8 text-center cursor-pointer h-40 flex flex-col justify-center ${
          isDragActive ? "bg-gray-700" : ""
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-base">Drop the image files here ...</p>
        ) : (
          <div>
            <p className="text-base mb-2">
              Drag 'n' drop image files here, or click to select files
            </p>
            <p className="text-sm text-gray-400">
              Include 10-20 thumbnails with letterings removed for better
              training (.png, .jpg, .jpeg).
            </p>
          </div>
        )}
      </div>

      {/* Add help toggle button */}
      <button
        onClick={() => setShowHelp(!showHelp)}
        className="flex items-center gap-2 text-sm text-gray-400 hover:text-white mb-2"
      >
        <IconHelpCircle size={16} />
        {showHelp ? "Hide help" : "Need help getting thumbnails?"}
      </button>

      {/* Conditionally render help section */}
      {showHelp && (
        <div className="mb-8 p-4 bg-[#1A1A1F] rounded-lg">
          <p className="text-sm text-gray-400 mb-2">
            You can download YouTube thumbnails using this format:
          </p>
          <code className="block bg-[#0B0B0F] p-3 rounded text-sm text-gray-300 font-mono">
            https://i.ytimg.com/vi/
            <span className="text-red-500">{`{VIDEO_ID}`}</span>
            /maxresdefault.jpg
          </code>
          <p className="text-sm text-gray-400 mt-2">
            Replace <span className="text-red-500">{`{VIDEO_ID}`}</span> with
            the ID from your YouTube video URL (e.g., ktyJIj6i4Qw)
          </p>
        </div>
      )}

      {uploading && (
        <div className="mb-4 h-1 w-full bg-gray-700">
          <div className="h-1 bg-red-500 animate-pulse"></div>
        </div>
      )}
      {loading ? (
        <div className="flex justify-center items-center mt-8">
          <CircularProgress color="inherit" size={40} />
        </div>
      ) : trainings.length === 0 ? (
        <div className="text-center mt-8">
          <p className="text-gray-400">
            No trainings found. Please upload image files to start a new
            training.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {trainings.map((training, index) => (
            <React.Fragment key={training.id}>
              <div className="flex items-center justify-between py-2">
                <div className="flex items-center space-x-6 flex-grow">
                  <Tooltip title={training.status} arrow placement="top">
                    {getStatusIcon(training.status)}
                  </Tooltip>
                  <div className="flex-grow">
                    <div className="flex items-center space-x-2">
                      <p className="text-base font-semibold truncate">
                        {training.name}
                      </p>
                      {training.description && (
                        <p className="text-sm text-gray-400 truncate">
                          / {training.description}
                        </p>
                      )}
                    </div>
                    <p className="text-sm text-gray-400 mt-1">
                      {training.createdAt.format("DD MMM YY")}
                      {getStatusMessage(
                        training.status,
                        training.modelId || "",
                        training.errorMessage
                      )}
                    </p>
                  </div>
                </div>
                {training.publicLink && (
                  <Tooltip title="Download ZIP" arrow>
                    <a
                      href={training.publicLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-white ml-4"
                    >
                      <IconDownload size={20} />
                    </a>
                  </Tooltip>
                )}
              </div>
              {index < trainings.length - 1 && (
                <div className="border-t border-gray-800" />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "#0B0B0F",
            color: "white",
            fontFamily: "'YouTubeSansRegular', sans-serif",
            minWidth: "600px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "'YouTubeSansRegular', sans-serif",
            fontSize: "1rem",
            paddingBottom: 2,
          }}
        >
          Start Model Training
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Model"
            type="text"
            fullWidth
            value={newTrainingName}
            onChange={(e) => setNewTrainingName(e.target.value)}
            InputLabelProps={{
              style: { color: "rgba(255, 255, 255, 0.7)" },
            }}
            InputProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#282828",
                },
                "&:hover fieldset": {
                  borderColor: "#484848",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FF0000",
                },
              },
            }}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newTrainingDescription}
            onChange={(e) => setNewTrainingDescription(e.target.value)}
            InputLabelProps={{
              style: { color: "rgba(255, 255, 255, 0.7)" },
            }}
            InputProps={{
              style: { color: "white" },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#282828",
                },
                "&:hover fieldset": {
                  borderColor: "#484848",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FF0000",
                },
              },
            }}
          />
          <p className="mt-4 text-sm text-gray-400">
            Selected files: {selectedFiles.map((file) => file.name).join(", ")}
          </p>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDialog}>Cancel</CustomButton>
          <CustomButton
            onClick={handleStartTraining}
            disabled={!newTrainingName || uploading}
          >
            {uploading ? "Starting..." : "Start"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModelsScreen;
