import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { Training } from "../models/training";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import moment from "moment";

export default class TrainingDb extends BaseDb<
  Training,
  { userId: string; trainingId: string }
> {
  _path: string = "users/userId/trainings/trainingId";

  getConverter(): FirestoreDataConverter<Training> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Training({
          id: snapshot.id,
          name: data.name,
          description: data.description,
          status: data.status,
          createdAt: moment(data.createdAt.toDate()),
          updatedAt: moment(data.updatedAt.toDate()),
          modelId: data.modelId,
          errorMessage: data.errorMessage,
          publicLink: data.publicLink,
          identifier: data.identifier,
        });
      },
      toFirestore: (training) => {
        return toFirestore(omit(training, ["id"]));
      },
    };
  }
}
