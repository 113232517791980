import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { Click } from "../models/click";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import moment from "moment";

export default class ClickDb extends BaseDb<
  Click,
  { userId: string; campaignId: string; clickId: string }
> {
  _path: string = "users/userId/campaigns/campaignId/clicks/clickId";

  getConverter(): FirestoreDataConverter<Click> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Click({
          id: snapshot.id,
          linkId: data.linkId,
          campaignId: data.campaignId,
          videoId: data.videoId,
          timestamp: moment(data.timestamp.toDate()),
          ip: data.ip,
          userAgent: data.userAgent,
        });
      },
      toFirestore: (click) => {
        return toFirestore(omit(click, ["id"]));
      },
    };
  }
}
