import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { Model } from "../models/model";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import moment from "moment";

export default class ModelDb extends BaseDb<
  Model,
  { userId: string; modelId: string }
> {
  _path: string = "users/userId/models/modelId";

  getConverter(): FirestoreDataConverter<Model> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Model({
          id: snapshot.id,
          name: data.name,
          type: data.type,
          identifier: data.identifier,
          description: data.description,
          createdAt: moment(data.createdAt.toDate()),
          basePrompt: data.basePrompt,
        });
      },
      toFirestore: (model) => {
        return toFirestore(omit(model, ["id"]));
      },
    };
  }
}
