import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { Generation } from "../models/generation";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";

export default class GenerationDb extends BaseDb<
  Generation,
  { userId: string; generationId: string }
> {
  _path: string = "users/userId/generations/generationId";

  getConverter(): FirestoreDataConverter<Generation> {
    return {
      fromFirestore: (snapshot, _options) => {
        return Generation.fromFirestore(snapshot);
      },
      toFirestore: (generation) => {
        return toFirestore(omit(generation, ["id"]));
      },
    };
  }
}
