import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  className,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
