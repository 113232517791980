import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import moment from "moment";
import { Channel } from "../models/channel";

export default class ChannelDb extends BaseDb<
  Channel,
  { userId: string; channelId: string }
> {
  _path: string = "users/userId/channels/channelId";

  getConverter(): FirestoreDataConverter<Channel> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Channel({
          id: snapshot.id,
          gchannel_ref: data.gchannel_ref,
          mine: data.mine,
          title: data.title,
          thumbnails: data.thumbnails,
        });
      },
      toFirestore: (channel) => {
        return toFirestore(omit(channel, ["id"]));
      },
    };
  }
}
