import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export interface ITransaction {
  id?: string;
  amount: number;
  currency: string;
  campaignId: string;
  videoId: string;
  linkId: string;
  timestamp: Moment;
}

export class Transaction implements ITransaction {
  public id?: string;
  public amount!: number;
  public currency!: string;
  public campaignId!: string;
  public videoId!: string;
  public linkId!: string;
  public timestamp!: Moment;

  constructor(param: ITransaction) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Transaction {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Transaction({
      id: doc.id,
      amount: data.amount,
      currency: data.currency,
      campaignId: data.campaignId,
      videoId: data.videoId,
      linkId: data.linkId,
      timestamp: moment(data.timestamp.toDate()),
    });
  }

  public toFirestore(): Partial<ITransaction> {
    return {
      amount: this.amount,
      currency: this.currency,
      campaignId: this.campaignId,
      videoId: this.videoId,
      linkId: this.linkId,
      timestamp: this.timestamp,
    };
  }

  public static groupByVideo(transactions: Transaction[]): {
    [videoId: string]: number;
  } {
    return transactions.reduce((acc, transaction) => {
      if (!acc[transaction.videoId]) {
        acc[transaction.videoId] = 0;
      }
      acc[transaction.videoId] += transaction.amount;
      return acc;
    }, {} as { [videoId: string]: number });
  }
}
