declare global {
  interface Window {
    sa_event?: (eventName: string, eventData?: any) => void;
  }
}

export const trackEvent = (eventName: string, eventData?: any) => {
  if (window.sa_event) {
    window.sa_event(eventName, eventData);
  }
};
