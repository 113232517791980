import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export enum ModelType {
  THUMBNAIL = "thumbnail",
}

export interface IModel {
  id: string;
  name: string;
  type: ModelType;
  identifier: string;
  description: string;
  createdAt: Moment;
  basePrompt: string;
}

export class Model implements IModel {
  public id!: string;
  public name!: string;
  public type!: ModelType;
  public identifier!: string;
  public description!: string;
  public createdAt!: Moment;
  public basePrompt!: string;

  constructor(param: IModel) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Model {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Model({
      id: doc.id,
      name: data.name,
      type: data.type,
      identifier: data.identifier,
      description: data.description,
      createdAt: moment(data.createdAt.toDate()),
      basePrompt: data.basePrompt,
    });
  }
}
