import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export interface IGeneration {
  id?: string;
  modelIdentifier: string;
  prompt: string;
  input: object;
  outputUrl: string;
  createdAt: Moment;
  image?: string;
}

export class Generation implements IGeneration {
  public id?: string;
  public modelIdentifier!: string;
  public prompt!: string;
  public input!: object;
  public outputUrl!: string;
  public createdAt!: Moment;
  public image?: string;

  constructor(param: IGeneration) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Generation {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Generation({
      id: doc.id,
      modelIdentifier: data.modelIdentifier,
      prompt: data.prompt,
      input: data.input,
      outputUrl: data.outputUrl,
      createdAt: moment(data.createdAt.toDate()),
      image: data.image,
    });
  }
}
