import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useMediaQuery, useTheme } from "@mui/material";
import SideBar from "./sideBar";
import PopMenu from "./popMenu";
import ThumbsScreen from "./thumbsScreen";
import ModelsScreen from "./modelsScreen";
import CreditsScreen from "./creditsScreen";

export default function ThumbGenieApp() {
  const navigate = useNavigate();
  const auth = getAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/thumb-genie");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  return (
    <div className="flex">
      {!isMobile && <SideBar />}
      <div className="flex-1">
        <PopMenu />
        <Routes>
          <Route path="/" element={<ThumbsScreen />} />
          <Route path="/models" element={<ModelsScreen />} />
          <Route path="/credits" element={<CreditsScreen />} />
          <Route path="*" element={<ThumbsScreen />} />
        </Routes>
      </div>
    </div>
  );
}
