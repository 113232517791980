import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";
import UAParser from "ua-parser-js";

export interface IClick {
  id: string;
  linkId: string;
  campaignId: string;
  videoId: string;
  timestamp: Moment;
  ip: string;
  userAgent: string;
  browser?: string;
  os?: string;
  device?: string;
}

export class Click implements IClick {
  public id!: string;
  public linkId!: string;
  public campaignId!: string;
  public videoId!: string;
  public timestamp!: Moment;
  public ip!: string;
  public userAgent!: string;
  public browser?: string;
  public os?: string;
  public device?: string;

  constructor(param: IClick) {
    Object.assign(this, param);
    this.parseUserAgent();
  }

  private parseUserAgent() {
    if (this.userAgent && this.userAgent.trim() !== "") {
      const parser = new UAParser(this.userAgent);
      this.browser = parser.getBrowser().name || undefined;
      this.os = parser.getOS().name || undefined;
      this.device = parser.getDevice().model || undefined;
    } else {
      this.browser = undefined;
      this.os = undefined;
      this.device = undefined;
    }
  }

  public static fromFirestore(doc: DocumentSnapshot): Click {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Click({
      id: doc.id,
      linkId: data.linkId,
      campaignId: data.campaignId,
      videoId: data.videoId,
      timestamp: moment(data.timestamp.toDate()),
      ip: data.ip,
      userAgent: data.userAgent,
    });
  }
}
