import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

interface CustomButtonProps {
  onClick: () => void;
  children: ReactNode;
  sx?: SxProps;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  loadingText?: string;
  variant?: "text" | "outlined" | "contained";
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  sx,
  disabled,
  startIcon,
  endIcon,
  loading,
  loadingText = "Loading...",
  variant = "outlined",
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      sx={{
        fontFamily: "'YouTubeSansRegular', sans-serif",
        borderColor: "white",
        color: "white",
        textTransform: "none",
        borderRadius: "9999px",
        paddingLeft: "20px",
        paddingRight: "20px",
        "&:hover": {
          backgroundColor: "#4B5563",
          borderColor: "white",
        },
        "&:focus": {
          borderColor: "white",
        },
        "&.Mui-disabled": {
          borderColor: "#282828",
          color: "#282828",
        },
        ...sx,
      }}
      disabled={disabled || loading}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : startIcon
      }
      endIcon={endIcon}
    >
      {loading ? loadingText : children}
    </Button>
  );
};

export default CustomButton;
