import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCijnGAPzCzuzIyCANLhntXMzw_GTdDGWk",
  authDomain: "soc-ia-l.firebaseapp.com",
  projectId: "soc-ia-l",
  storageBucket: "soc-ia-l.appspot.com",
  messagingSenderId: "833234270929",
  appId: "1:833234270929:web:c2cee56cd900b4bbf323f0",
};

const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

export default app;
