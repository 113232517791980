import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "white",
          "&.Mui-selected": {
            color: "white",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.7)",
          fontFamily: "'YouTubeSansRegular', sans-serif",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "white",
          fontFamily: "'YouTubeSansRegular', sans-serif",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#282828",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#484848",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF0000",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "white",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'YouTubeSansRegular', sans-serif",
          "&:hover": {
            backgroundColor: "#282828",
          },
          "&.Mui-selected": {
            backgroundColor: "#282828",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#383838",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonGroup-grouped": {
            borderColor: "#282828",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'YouTubeSansRegular', sans-serif",
          textTransform: "none",
          color: "white",
          "&:hover": {
            borderColor: "#484848",
            backgroundColor: "transparent",
          },
          "&.Mui-selected": {
            backgroundColor: "#FF0000",
            color: "white",
            "&:hover": {
              backgroundColor: "#CC0000",
            },
          },
        },
        outlined: {
          borderColor: "#282828",
        },
        contained: {
          backgroundColor: "#FF0000",
          "&:hover": {
            backgroundColor: "#CC0000",
          },
        },
      },
    },
  },
});

export default theme;
