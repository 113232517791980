import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export enum TrainingStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface ITraining {
  id: string;
  name: string;
  description: string;
  status: TrainingStatus;
  createdAt: Moment;
  updatedAt: Moment;
  modelId?: string;
  errorMessage?: string;
  publicLink?: string;
  identifier?: string;
}

export class Training implements ITraining {
  public id!: string;
  public name!: string;
  public description!: string;
  public status!: TrainingStatus;
  public createdAt!: Moment;
  public updatedAt!: Moment;
  public modelId?: string;
  public errorMessage?: string;
  public publicLink?: string;
  public identifier?: string;

  constructor(param: ITraining) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Training {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Training({
      id: doc.id,
      name: data.name,
      description: data.description,
      status: data.status,
      createdAt: moment(data.createdAt.toDate()),
      updatedAt: moment(data.updatedAt.toDate()),
      modelId: data.modelId,
      errorMessage: data.errorMessage,
      publicLink: data.publicLink,
      identifier: data.identifier,
    });
  }
}
