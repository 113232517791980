import BaseDb from "./baseDb";
import { Campaign } from "../models/campaign";
import { FirestoreDataConverter } from "firebase/firestore";
import moment from "moment";
import { omit } from "lodash";
import toFirestore from "./toFirestore";

export default class CampaignDb extends BaseDb<
  Campaign,
  { userId: string; campaignId: string }
> {
  _path: string = "users/userId/campaigns/campaignId";

  getConverter(): FirestoreDataConverter<Campaign> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Campaign({
          id: snapshot.id,
          name: data.name,
          channelId: data.channelId,
          message: data.message,
          ctaLink: data.ctaLink,
          videoIds: data.videoIds || [],
          videos: data.videos || [],
          startDate: moment(data.startDate.toDate()),
          endDate: data.endDate ? moment(data.endDate.toDate()) : null,
          mode: data.mode,
          videoCount: data.videoCount,
          searchTerm: data.searchTerm,
        });
      },
      toFirestore: (campaign) => {
        return toFirestore(omit(campaign, ["id"]));
      },
    };
  }
}
