import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { GoogleAuthProvider } from "firebase/auth";
import { IconCalendar, IconBrandGoogleFilled } from "@tabler/icons-react";
import CustomButton from "../utils/customButton";
import { signInWithProvider } from "../../contexts/userContext";
import Footer from "../utils/footer";

export default function InsightGeniePublic() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithProvider(provider);
      navigate("/app/insight-genie");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <a href="/">
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="h-8 cursor-pointer"
          />
        </a>
      </header>
      <main className="text-center mt-12 max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-6 md:text-6xl">InsightGenie</h1>
        <p className="mb-6 text-gray-300">
          Unlock the full potential of your channel with our comprehensive
          analytics and insights platform.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <CustomButton
            variant="contained"
            startIcon={<IconCalendar size={20} />}
            onClick={() =>
              window.open(
                "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                "_blank"
              )
            }
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": { backgroundColor: theme.palette.primary.dark },
            }}
          >
            Book a Demo
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<IconBrandGoogleFilled size={20} />}
            onClick={handleSignIn}
            sx={{
              backgroundColor: "#282828",
              "&:hover": { backgroundColor: "#383838" },
            }}
          >
            Sign in with Google
          </CustomButton>
        </div>
        <img
          src="/images/insight-genie.png"
          className="mt-12 max-w-full h-auto"
          alt="InsightGenie Dashboard"
        />
      </main>
      <Footer />
    </div>
  );
}
