import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { UserContext } from "../../../contexts/userContext";
import { Channel } from "../../../models/channel";
import ChannelDb from "../../../db/channelDb";
import { getDocs } from "firebase/firestore";
import { CircularProgress, Tabs, Tab, Collapse, Tooltip } from "@mui/material";
import Functions from "../../../callable/functions";
import {
  IconBrandYoutube,
  IconChevronDown,
  IconChevronUp,
  IconTrendingUp,
  IconEye,
  IconClockHour4,
} from "@tabler/icons-react";
import moment from "moment";

const timePeriods = ["Last 7 days", "Last 28 days"];

interface Metric {
  current: number;
  change: number;
  percentage: number;
}

interface ChannelMetrics {
  subscribers: Metric;
  views: Metric;
  videos: Metric;
}

interface VideoMetrics {
  id: string;
  title: string;
  views: number;
  vph: number;
  outlierScore: number;
  isShort: boolean;
  publishedAt: string;
}

export default function AnalyticsScreen() {
  const user = useContext(UserContext);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [metrics, setMetrics] = useState<{ [key: string]: ChannelMetrics }>({});
  const [loading, setLoading] = useState(true);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(0);
  const [expandedChannels, setExpandedChannels] = useState<{
    [key: string]: boolean;
  }>({});
  const [videoMetrics, setVideoMetrics] = useState<{
    [key: string]: VideoMetrics[];
  }>({});
  const [loadingVideos, setLoadingVideos] = useState<{
    [key: string]: boolean;
  }>({});
  const [isReady, setIsReady] = useState(false);

  const functions = useMemo(() => new Functions(), []);

  const fetchChannels = useCallback(async () => {
    if (user) {
      const channelDb = new ChannelDb();
      const querySnapshot = await getDocs(
        channelDb.collection({ userId: user.id, channelId: "" })
      );
      const channelsData = querySnapshot.docs.map(
        (doc) => doc.data() as Channel
      );
      setChannels(channelsData);
    }
  }, [user]);

  const fetchMetrics = useCallback(async () => {
    if (user && channels.length > 0) {
      const newMetrics: { [key: string]: ChannelMetrics } = {};
      for (const channel of channels) {
        try {
          const result = await functions.fetchChannelMetrics({
            userId: user.id,
            channelId: channel.id,
            timePeriod: timePeriods[selectedTimePeriod],
          });
          newMetrics[channel.id] = result.data;
        } catch (error) {
          console.error(
            "Error fetching metrics for channel",
            channel.id,
            error
          );
        }
      }
      setMetrics(newMetrics);
    }
  }, [user, channels, selectedTimePeriod, functions]);

  const fetchVideoMetrics = useCallback(
    async (channelId: string) => {
      if (user && !videoMetrics[channelId]) {
        try {
          const result = await functions.fetchVideoMetrics({
            userId: user.id,
            channelId,
            timePeriod: timePeriods[selectedTimePeriod],
          });
          setVideoMetrics((prev) => ({ ...prev, [channelId]: result.data }));
        } catch (error) {
          console.error(
            "Error fetching video metrics for channel",
            channelId,
            error
          );
        } finally {
          setLoadingVideos((prev) => ({ ...prev, [channelId]: false }));
        }
      }
    },
    [user, videoMetrics, selectedTimePeriod, functions]
  );

  const toggleChannelExpansion = useCallback(
    (channelId: string) => {
      setExpandedChannels((prev) => ({
        ...prev,
        [channelId]: !prev[channelId],
      }));
      if (!videoMetrics[channelId]) {
        setLoadingVideos((prev) => ({ ...prev, [channelId]: true }));
        fetchVideoMetrics(channelId);
      }
    },
    [videoMetrics, fetchVideoMetrics]
  );

  useEffect(() => {
    setLoading(true);
    fetchChannels().then(() => setLoading(false));
  }, [fetchChannels]);

  useEffect(() => {
    if (channels.length > 0) {
      fetchMetrics();
    }
  }, [fetchMetrics, channels, selectedTimePeriod]);

  useEffect(() => {
    setIsReady(true);
  }, []);

  const handleTimePeriodChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTimePeriod(newValue);
  };

  const formatChange = (change: number, percentage: number) => {
    const sign = change >= 0 ? "+" : "";
    return `${sign}${change.toLocaleString()} (${percentage.toFixed(1)}%)`;
  };

  const formatMetric = (value: number) => {
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value.toString();
  };

  function renderVideoList(channelId: string, isShort: boolean) {
    const filteredVideos = videoMetrics[channelId]?.filter(
      (video) => video.isShort === isShort
    );

    if (!filteredVideos || filteredVideos.length === 0) {
      return (
        <p className="text-sm text-gray-400">
          No {isShort ? "Shorts" : "videos"} found.
        </p>
      );
    }

    return (
      <table className="min-w-full divide-y divide-gray-700">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 tracking-wider">
              Title
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 tracking-wider">
              Published
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 tracking-wider">
              Outlier
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 tracking-wider">
              Views
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 tracking-wider">
              VPH
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {filteredVideos.map((video) => (
            <tr key={video.id}>
              <td className="px-4 py-2 whitespace-nowrap">
                <Tooltip
                  title={
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        height: "auto",
                      }}
                    >
                      <iframe
                        width="100%"
                        height={isShort ? "400" : "225"}
                        src={`https://www.youtube.com/embed/${video.id}`}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ borderRadius: "8px" }}
                      ></iframe>
                    </div>
                  }
                  placement="top"
                >
                  <span className="text-sm truncate flex-1 cursor-pointer hover:underline flex items-center">
                    {video.title}
                  </span>
                </Tooltip>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                <span className="text-sm text-gray-400">
                  {moment(video.publishedAt).format("MMM D, YYYY")}
                </span>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                {video.outlierScore >= 1.5 && (
                  <div className="flex items-center" title="Outlier">
                    <IconTrendingUp size={16} className="mr-1 text-[#FF0000]" />
                    <span className="text-sm font-bold text-[#FF0000]">
                      {video.outlierScore.toFixed(1)}x
                    </span>
                  </div>
                )}
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center" title="Views">
                  <IconEye size={16} className="mr-1 text-gray-400" />
                  <span className="text-sm font-bold text-white">
                    {formatMetric(video.views)}
                  </span>
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center" title="Views Per Hour">
                  <IconClockHour4 size={16} className="mr-1 text-gray-400" />
                  <span className="text-sm font-bold text-white">
                    {video.vph.toFixed(1)}{" "}
                    <span className="text-gray-400">VPH</span>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  if (!isReady) {
    return null;
  }

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      <Tabs
        value={selectedTimePeriod}
        onChange={handleTimePeriodChange}
        variant="scrollable"
        scrollButtons="auto"
        className="mb-4"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#FF0000",
          },
        }}
        sx={{
          "& .MuiTab-root": {
            fontWeight: "bold",
            color: "white",
            fontFamily: "'YouTubeSansRegular', sans-serif",
            textTransform: "none",
            "&.Mui-selected": {
              color: "white",
            },
          },
          "& .MuiTabs-scroller": {
            borderBottom: "2px solid #282828",
          },
        }}
      >
        {timePeriods.map((period, index) => (
          <Tab
            key={index}
            label={period}
            className="font-custom"
            style={{
              color: "white",
              opacity: index === selectedTimePeriod ? 1 : 0.7,
            }}
          />
        ))}
      </Tabs>

      {channels.map((channel, index) => (
        <div
          key={channel.id}
          className={`py-4 ${
            index !== channels.length - 1 ? "border-b border-gray-700" : ""
          }`}
        >
          <div
            className="cursor-pointer"
            onClick={() => toggleChannelExpansion(channel.id)}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                {channel.thumbnails?.default?.url ? (
                  <img
                    src={channel.thumbnails.default.url}
                    alt={channel.title}
                    className="w-6 h-6 rounded-full"
                  />
                ) : (
                  <IconBrandYoutube size={20} className="text-[#FF0000]" />
                )}
                <span className="text-base font-customBold">
                  {channel.title}
                </span>
              </div>
              {expandedChannels[channel.id] ? (
                <IconChevronUp size={20} />
              ) : (
                <IconChevronDown size={20} />
              )}
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-xs text-gray-400 mb-1">Subscribers</p>
                <div className="flex items-baseline">
                  <p className="text-sm font-bold mr-2">
                    {formatMetric(
                      metrics[channel.id]?.subscribers.current || 0
                    )}
                  </p>
                  <p className="text-xs text-green-500">
                    {formatChange(
                      metrics[channel.id]?.subscribers.change || 0,
                      metrics[channel.id]?.subscribers.percentage || 0
                    )}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-400 mb-1">Views</p>
                <div className="flex items-baseline">
                  <p className="text-sm font-bold mr-2">
                    {formatMetric(metrics[channel.id]?.views.current || 0)}
                  </p>
                  <p className="text-xs text-green-500">
                    {formatChange(
                      metrics[channel.id]?.views.change || 0,
                      metrics[channel.id]?.views.percentage || 0
                    )}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-400 mb-1">Videos</p>
                <div className="flex items-baseline">
                  <p className="text-sm font-bold mr-2">
                    {formatMetric(metrics[channel.id]?.videos.current || 0)}
                  </p>
                  <p className="text-xs text-green-500">
                    {formatChange(
                      metrics[channel.id]?.videos.change || 0,
                      metrics[channel.id]?.videos.percentage || 0
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Collapse in={expandedChannels[channel.id]}>
            <div className="pt-4">
              {loadingVideos[channel.id] ? (
                <div className="flex justify-center items-center py-4">
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : (
                <>
                  <div className="mt-4">
                    <h3 className="text-sm font-bold mb-2">Videos</h3>
                    {renderVideoList(channel.id, false)}
                  </div>
                  <div className="mt-4">
                    <h3 className="text-sm font-bold mb-2">Shorts</h3>
                    {renderVideoList(channel.id, true)}
                  </div>
                </>
              )}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
}
