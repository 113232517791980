import { isNull, isUndefined } from "lodash";
import { isMoment } from "moment";

export default function toFirestore<T>(toBeSaved: T) {
  const objT: Pick<T, keyof T> = { ...toBeSaved };
  let objDb = objT as any;
  Object.keys(objDb).forEach((key) => {
    if (isUndefined(objDb[key]) || isNull(objDb[key])) delete objDb[key];
    else if (isMoment(objDb[key])) objDb[key] = objDb[key].toDate();
    else if (
      Array.isArray(objDb[key]) &&
      objDb[key].every((item: any) => typeof item === "string")
    ) {
      return;
    } else if (Array.isArray(objDb[key]))
      objDb[key] = objDb[key].map(toFirestore);
    else if (typeof objDb[key] === "object")
      objDb[key] = toFirestore(objDb[key]);
    else return;
  });

  return objDb;
}
