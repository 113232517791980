import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export interface ICampaign {
  id?: string;
  name: string;
  channelId: string;
  message: string;
  ctaLink: string;
  videoIds: string[];
  videos: {
    videoId: string;
    originalDescription: string;
    originalPinnedComment: string | null;
    updatedPinnedCommentId: string | null;
  }[];
  startDate: Moment;
  endDate: Moment | null;
  mode: string;
  videoCount: number;
  searchTerm?: string;
}

export class Campaign implements ICampaign {
  public id?: string;
  public name!: string;
  public channelId!: string;
  public message!: string;
  public ctaLink!: string;
  public videoIds!: string[];
  public videos!: {
    videoId: string;
    originalDescription: string;
    originalPinnedComment: string | null;
    updatedPinnedCommentId: string | null;
  }[];
  public startDate!: Moment;
  public endDate!: Moment | null;
  public mode!: string;
  public videoCount!: number;
  public searchTerm?: string;

  constructor(param: ICampaign) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Campaign {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Campaign({
      id: doc.id,
      name: data.name,
      channelId: data.channelId,
      message: data.message,
      ctaLink: data.ctaLink,
      videoIds: data.videoIds || [],
      videos: data.videos || [],
      startDate: moment(data.startDate.toDate()),
      endDate: data.endDate ? moment(data.endDate.toDate()) : null,
      mode: data.mode,
      videoCount: data.videoCount,
      searchTerm: data.searchTerm,
    });
  }

  public toFirestore(): Partial<ICampaign> {
    return {
      name: this.name,
      channelId: this.channelId,
      message: this.message,
      ctaLink: this.ctaLink,
      videoIds: this.videoIds,
      videos: this.videos,
      startDate: this.startDate,
      endDate: this.endDate ? this.endDate : null,
      mode: this.mode,
      videoCount: this.videoCount,
      searchTerm: this.searchTerm,
    };
  }
}
