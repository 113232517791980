import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import { youtube_v3 } from "googleapis";

export interface IChannel {
  id: string;
  gchannel_ref: DocumentReference;
  mine: boolean;
  title: string;
  thumbnails?: youtube_v3.Schema$ThumbnailDetails;
}

export class Channel implements IChannel {
  public id!: string;
  public gchannel_ref!: DocumentReference;
  public mine!: boolean;
  public title!: string;
  public thumbnails?: youtube_v3.Schema$ThumbnailDetails;

  constructor(param: IChannel) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Channel {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Channel({
      id: doc.id,
      gchannel_ref: data.gchannel_ref,
      mine: data.mine,
      title: data.title,
      thumbnails: data.thumbnails,
    });
  }
}
