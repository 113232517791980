import React from "react";
import { Tabs, Tab } from "@mui/material";
import { IconBrandYoutube } from "@tabler/icons-react";
import { Channel } from "../../../models/channel";

interface ChannelTabsProps {
  channels: Channel[];
  selectedTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ChannelTabs: React.FC<ChannelTabsProps> = ({
  channels,
  selectedTab,
  handleTabChange,
}) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: {
          backgroundColor: "#FF0000",
        },
      }}
      className="mb-5"
      sx={{
        ".MuiTab-root": {
          fontWeight: "bold",
          color: "white",
          fontFamily: "font-custom",
          textTransform: "none",
          "&.Mui-selected": {
            color: "white",
          },
        },
        "& .MuiTabs-scroller": {
          borderBottom: "2px solid #282828",
        },
      }}
    >
      {channels.map((channel, index) => (
        <Tab
          key={channel.id}
          label={
            <div className="flex items-center space-x-2">
              {channel.thumbnails?.default?.url ? (
                <img
                  src={channel.thumbnails.default.url}
                  alt={channel.title}
                  className="w-6 h-6 rounded-full"
                />
              ) : (
                <IconBrandYoutube size={20} className="text-[#FF0000]" />
              )}
              <span className="text-base font-custom">{channel.title}</span>
            </div>
          }
          className="normal-case"
        />
      ))}
    </Tabs>
  );
};

export default ChannelTabs;
