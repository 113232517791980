import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  Divider,
  Grid,
  Tooltip as MUITooltip,
} from "@mui/material";
import { doc, getDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { Campaign } from "../../../models/campaign";
import { Click } from "../../../models/click";
import ClickDb from "../../../db/clickDb";

import {
  IconClick,
  IconVideo,
  IconCurrencyDollar,
  IconArrowDownRight,
  IconCopy,
} from "@tabler/icons-react";
import moment from "moment";
import TransactionDb from "../../../db/transactionDb";
import { Transaction } from "../../../models/transaction";
import Functions from "../../../callable/functions";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import VideoPreview from "../../utils/videoPreview";

const CampaignDetailsScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const user = useContext(UserContext);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [clicks, setClicks] = useState<Click[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [viewCount, setViewCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const functions = new Functions();

  const [copiedWebhook, setCopiedWebhook] = useState(false);
  const [copiedPayload, setCopiedPayload] = useState(false);

  const webhookUrl = "https://handlewebhook-ueo43gflpq-uc.a.run.app";
  const webhookPayloadExample = `{
  "amount": 397, // Replace with actual transaction amount
  "currency": "R$", // Replace with actual currency ($, R$ etc.)
  "utm_content": "LINK_ID", // Replace with actual Link ID, just send us back the utm_content on the browser.
  "userId": "${user?.id}"
}`;

  const copyToClipboard = (
    text: string,
    setCopied: (value: boolean) => void
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      if (user && id) {
        setLoading(true);
        try {
          const campaignRef = doc(db, `users/${user.id}/campaigns/${id}`);
          const campaignDoc = await getDoc(campaignRef);

          if (campaignDoc.exists()) {
            setCampaign(Campaign.fromFirestore(campaignDoc));

            const clickDb = new ClickDb();
            const clicksQuery = query(
              clickDb.collection({
                userId: user.id,
                campaignId: id,
                clickId: "",
              }),
              where("campaignId", "==", id)
            );

            const transactionDb = new TransactionDb();
            const transactionsQuery = query(
              transactionDb.collection({
                userId: user.id,
                campaignId: id,
                transactionId: "",
              })
            );

            // Fetch clicks, transactions, and view count concurrently
            const [clicksSnapshot, transactionsSnapshot, viewCountResult] =
              await Promise.all([
                getDocs(clicksQuery),
                getDocs(transactionsQuery),
                functions.getCampaignViewCount({
                  userId: user.id,
                  campaignId: id,
                }),
              ]);

            setClicks(
              clicksSnapshot.docs.map((doc) => Click.fromFirestore(doc))
            );
            setTransactions(
              transactionsSnapshot.docs.map((doc) =>
                Transaction.fromFirestore(doc)
              )
            );
            setViewCount(viewCountResult.data.totalViewCount);
          } else {
            console.error("Campaign not found");
          }
        } catch (error) {
          console.error("Error fetching campaign details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCampaignDetails();
  }, [user, id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress color="inherit" size={40} />
      </div>
    );
  }

  if (!campaign) {
    return <div>Campaign not found</div>;
  }

  const getTopVideos = () => {
    const videoClicksData = campaign.videos.map((video) => ({
      videoId: video.videoId,
      clicks: clicks.filter((click) => click.videoId === video.videoId).length,
    }));
    return videoClicksData.sort((a, b) => b.clicks - a.clicks).slice(0, 5);
  };

  const getTopEarningVideos = () => {
    const videoEarnings = Transaction.groupByVideo(transactions);
    return campaign.videos
      .map((video) => ({
        videoId: video.videoId,
        earnings: videoEarnings[video.videoId] || 0,
      }))
      .sort((a, b) => b.earnings - a.earnings)
      .slice(0, 5);
  };

  const getStatusColor = (endDate: moment.Moment | null) => {
    if (!endDate) return "text-green-500";
    return moment().isBefore(endDate) ? "text-yellow-500" : "text-red-500";
  };

  const getStatusText = (endDate: moment.Moment | null) => {
    if (!endDate) return "Active";
    return moment().isBefore(endDate) ? "Scheduled" : "Ended";
  };

  const getTotalRevenue = () => {
    return transactions.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
  };

  const getConversionRate = () => {
    return clicks.length > 0 ? (transactions.length / clicks.length) * 100 : 0;
  };

  const getClickThroughRate = () => {
    return viewCount > 0 ? (clicks.length / viewCount) * 100 : 0;
  };

  const getAverageOrderValue = () => {
    return transactions.length > 0
      ? getTotalRevenue() / transactions.length
      : 0;
  };

  return (
    <div className="flex-1 min-h-screen bg-[#0B0B0F] text-white p-2 md:px-16 md:py-8 font-custom">
      <h4 className="text-base font-custom font-bold mb-2">Campaign Details</h4>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <h1 className="text-base font-bold mb-4">{campaign.name}</h1>
          <div className="space-y-4">
            <div>
              <p className="text-sm text-gray-400 mb-1">Message</p>
              <p className="text-base">{campaign.message}</p>
            </div>
            <div>
              <p className="text-sm text-gray-400 mb-1">CTA Link</p>
              <a
                href={campaign.ctaLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base text-blue-400 hover:underline"
              >
                {campaign.ctaLink}
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2 className="text-base font-bold mb-4">Campaign Overview</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Total Clicks</p>
              <p className="text-base font-semibold flex items-center">
                <IconClick size={20} className="mr-2 text-blue-400" />
                {clicks.length}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Total Videos</p>
              <p className="text-base font-semibold flex items-center">
                <IconVideo size={20} className="mr-2 text-green-400" />
                {campaign.videos.length}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Start Date</p>
              <p className="text-base font-semibold">
                {campaign.startDate.format("DD MMM YYYY")}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">End Date</p>
              <p className="text-base font-semibold">
                {campaign.endDate
                  ? campaign.endDate.format("DD MMM YYYY")
                  : "N/A"}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg col-span-2">
              <p className="text-sm text-gray-400 mb-1">Status</p>
              <p
                className={`text-base font-semibold ${getStatusColor(
                  campaign.endDate
                )}`}
              >
                {getStatusText(campaign.endDate)}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 1,
          marginTop: 8,
          marginBottom: 8,
        }}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <h2 className="text-base font-bold mb-4">Campaign Metrics</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Total Revenue</p>
              <p className="text-base font-semibold flex items-center">
                <IconCurrencyDollar size={20} className="mr-2 text-green-400" />
                {getTotalRevenue().toFixed(2)}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Conversion Rate</p>
              <p className="text-base font-semibold flex items-center">
                <IconArrowDownRight
                  size={20}
                  className="mr-2 text-yellow-400"
                />
                {getConversionRate().toFixed(2)}%
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Transactions</p>
              <p className="text-base font-semibold flex items-center">
                <IconCurrencyDollar size={20} className="mr-2 text-blue-400" />
                {transactions.length}
              </p>
            </div>
            <div className="bg-[#1E1E1E] p-4 rounded-lg">
              <p className="text-sm text-gray-400 mb-1">Avg. Order Value</p>
              <p className="text-base font-semibold flex items-center">
                <IconCurrencyDollar
                  size={20}
                  className="mr-2 text-purple-400"
                />
                {getAverageOrderValue().toFixed(2)}
              </p>
            </div>
            {viewCount > 0 && (
              <div className="bg-[#1E1E1E] p-4 rounded-lg">
                <p className="text-sm text-gray-400 mb-1">Click-Through Rate</p>
                <p className="text-base font-semibold flex items-center">
                  <IconClick size={20} className="mr-2 text-blue-400" />
                  {getClickThroughRate().toFixed(2)}%
                </p>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2 className="text-base font-bold mb-4">Funnel Analysis</h2>
          <div className="bg-[#1E1E1E] p-4 rounded-lg">
            <div className="flex flex-col items-center">
              {viewCount > 0 ? (
                <>
                  <div className="w-full max-w-xs bg-[#282828] rounded-t-lg p-4 mb-2">
                    <p className="text-center text-sm mb-1">Views</p>
                    <p className="text-center text-xl font-bold">
                      {viewCount.toLocaleString()}
                    </p>
                  </div>
                  <div className="w-0 h-0 border-l-[20px] border-l-transparent border-r-[20px] border-r-transparent border-t-[20px] border-t-[#282828] mb-2"></div>
                </>
              ) : (
                <div className="w-full max-w-xs bg-[#282828] rounded-t-lg p-4 mb-2">
                  <p className="text-center text-sm mb-1">
                    No view data available yet
                  </p>
                </div>
              )}
              <div className="w-full max-w-xs bg-[#282828] p-4 mb-2">
                <p className="text-center text-sm mb-1">Clicks</p>
                <p className="text-center text-xl font-bold">
                  {clicks.length.toLocaleString()}
                </p>
              </div>
              <div className="w-0 h-0 border-l-[20px] border-l-transparent border-r-[20px] border-r-transparent border-t-[20px] border-t-[#282828] mb-2"></div>
              <div className="w-full max-w-xs bg-[#282828] rounded-b-lg p-4">
                <p className="text-center text-sm mb-1">Transactions</p>
                <p className="text-center text-xl font-bold">
                  {transactions.length.toLocaleString()}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-center text-sm text-gray-400">
                Conversion Rate (Transactions / Clicks)
              </p>
              <p className="text-center text-lg font-bold">
                {getConversionRate().toFixed(2)}%
              </p>
            </div>
            {viewCount > 0 && (
              <div className="mt-4">
                <p className="text-center text-sm text-gray-400">
                  Click-Through Rate
                </p>
                <p className="text-center text-lg font-bold">
                  {getClickThroughRate().toFixed(2)}%
                </p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 1,
          marginTop: 8,
          marginBottom: 8,
        }}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <h2 className="text-base font-bold mb-4">Top Performing Videos</h2>
          <div className="bg-[#1E1E1E] p-4 rounded-lg">
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#282828]">
                  <th className="px-4 py-2 text-left text-sm font-bold text-gray-400">
                    Video ID
                  </th>
                  <th className="px-4 py-2 text-right text-sm font-bold text-gray-400">
                    Clicks
                  </th>
                </tr>
              </thead>
              <tbody>
                {getTopVideos().map((video, index) => (
                  <React.Fragment key={video.videoId}>
                    <tr className="hover:bg-[#282828] transition-colors duration-150">
                      <td className="px-4 py-2 text-sm">
                        <MUITooltip
                          title={<VideoPreview videoId={video.videoId} />}
                          arrow
                        >
                          <span className="cursor-pointer">
                            {video.videoId}
                          </span>
                        </MUITooltip>
                      </td>
                      <td className="px-4 py-2 text-right text-sm">
                        {video.clicks}
                      </td>
                    </tr>
                    {index < 4 && (
                      <tr>
                        <td colSpan={2}>
                          <Divider sx={{ borderColor: "#282828", margin: 0 }} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2 className="text-base font-bold mb-4">Top Earning Videos</h2>
          <div className="bg-[#1E1E1E] p-4 rounded-lg">
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#282828]">
                  <th className="px-4 py-2 text-left text-sm font-bold text-gray-400">
                    Video ID
                  </th>
                  <th className="px-4 py-2 text-right text-sm font-bold text-gray-400">
                    Earnings
                  </th>
                </tr>
              </thead>
              <tbody>
                {getTopEarningVideos().map((video, index) => (
                  <React.Fragment key={video.videoId}>
                    <tr className="hover:bg-[#282828] transition-colors duration-150">
                      <td className="px-4 py-2 text-sm">
                        <MUITooltip
                          title={<VideoPreview videoId={video.videoId} />}
                          arrow
                        >
                          <span className="cursor-pointer">
                            {video.videoId}
                          </span>
                        </MUITooltip>
                      </td>
                      <td className="px-4 py-2 text-right text-sm">
                        {transactions[0]?.currency || "$"}{" "}
                        {video.earnings.toFixed(2)}
                      </td>
                    </tr>
                    {index < 4 && (
                      <tr>
                        <td colSpan={2}>
                          <Divider sx={{ borderColor: "#282828", margin: 0 }} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>

      {transactions.length > 0 ? (
        <>
          <Divider
            sx={{
              borderColor: "#282828",
              borderBottomWidth: 1,
              marginTop: 8,
              marginBottom: 8,
            }}
          />

          <h2 className="text-base font-bold mb-4">Recent Transactions</h2>
          <div className="bg-[#1E1E1E] rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#282828]">
                  <th className="px-4 py-2 text-left text-sm font-bold text-gray-400">
                    Date
                  </th>
                  <th className="px-4 py-2 text-right text-sm font-bold text-gray-400">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions.slice(0, 5).map((transaction, index) => (
                  <React.Fragment key={transaction.id}>
                    <tr className="hover:bg-[#282828] transition-colors duration-150">
                      <td className="px-4 py-2 text-sm">
                        {transaction.timestamp.format("DD MMM YYYY HH:mm")}
                      </td>
                      <td className="px-4 py-2 text-right text-sm">
                        {transaction.currency} {transaction.amount.toFixed(2)}
                      </td>
                    </tr>
                    {index < 4 && (
                      <tr>
                        <td colSpan={2}>
                          <Divider sx={{ borderColor: "#282828", margin: 0 }} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <Divider
            sx={{
              borderColor: "#282828",
              borderBottomWidth: 1,
              marginTop: 8,
              marginBottom: 8,
            }}
          />

          <h2 className="text-base font-bold mb-4">
            How to Track Transactions
          </h2>
          <div className="bg-[#1E1E1E] p-4 rounded-lg">
            <p className="mb-4">
              To track transactions for this campaign, send a POST request to
              our webhook:
            </p>
            <div className="relative">
              <SyntaxHighlighter
                language="bash"
                style={atomDark}
                customStyle={{ fontSize: "0.8rem" }}
              >
                {webhookUrl}
              </SyntaxHighlighter>
              <button
                className="absolute top-2 right-2 p-1 bg-gray-700 rounded hover:bg-gray-600 transition-colors"
                onClick={() => copyToClipboard(webhookUrl, setCopiedWebhook)}
              >
                <IconCopy size={16} />
              </button>
              {copiedWebhook && (
                <span className="absolute top-2 right-10 text-xs text-green-400">
                  Copied!
                </span>
              )}
            </div>
            <p className="mt-4 mb-2">
              Include the following payload in your request:
            </p>
            <div className="relative">
              <SyntaxHighlighter
                language="json"
                style={atomDark}
                customStyle={{ fontSize: "0.8rem" }}
              >
                {webhookPayloadExample}
              </SyntaxHighlighter>
              <button
                className="absolute top-2 right-2 p-1 bg-gray-700 rounded hover:bg-gray-600 transition-colors"
                onClick={() =>
                  copyToClipboard(webhookPayloadExample, setCopiedPayload)
                }
              >
                <IconCopy size={16} />
              </button>
              {copiedPayload && (
                <span className="absolute top-2 right-10 text-xs text-green-400">
                  Copied!
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignDetailsScreen;
