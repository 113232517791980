import React from "react";
import { Alert, AlertProps, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: "#2C2C2C",
  color: "white",
  fontFamily: "YouTubeSansRegular, sans-serif",
  "& .MuiAlert-icon": {
    color: "#FF0000",
  },
}));

interface CustomAlertProps extends AlertProps {
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  onClose,
  autoHideDuration = 6000,
  children,
  ...props
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <StyledAlert onClose={onClose} {...props}>
        {children}
      </StyledAlert>
    </Snackbar>
  );
};

export default CustomAlert;
