import React, { useState, useEffect, PropsWithChildren } from "react";
import {
  AuthProvider,
  getAdditionalUserInfo,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { User as AuthUser } from "firebase/auth";
import { onSnapshot, setDoc } from "firebase/firestore";
import UserDb from "../db/userDb";
import { User } from "../models/user";
import { trackEvent } from "../utils/analytics";

export const UserContext = React.createContext<User | undefined>(undefined);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [uid, setUid] = useState<string>();

  useEffect(() => {
    return onAuthStateChanged(getAuth(), async (userAuth) => {
      if (userAuth) {
        setUid(userAuth.uid);
      } else {
        setUid(undefined);
      }
    });
  }, []);

  useEffect(() => {
    if (uid) {
      return onSnapshot(new UserDb().doc({ userId: uid }), (snapshot) => {
        setUser(snapshot.data());
      });
    } else {
      setUser(undefined);
    }
  }, [uid]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function signInWithProvider(provider: AuthProvider): Promise<void> {
  if (provider instanceof GoogleAuthProvider) {
    provider.addScope("https://www.googleapis.com/auth/youtube.readonly");
    provider.addScope("https://www.googleapis.com/auth/youtube.force-ssl");
  }

  return signInWithPopup(getAuth(), provider)
    .then((authResult) => {
      if (getAdditionalUserInfo(authResult) && authResult.user) {
        const credential = GoogleAuthProvider.credentialFromResult(authResult);
        const token = credential?.accessToken || null;
        if (getAdditionalUserInfo(authResult)?.isNewUser) {
          signUp(authResult.user, token);
        } else {
          updateToken(authResult.user.uid, token);
        }
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function signUp(user: AuthUser, token: string | null): Promise<void> {
  return setDoc(
    new UserDb().doc({
      userId: user.uid,
    }),
    new User({
      id: user.uid,
      email: user.email || "",
      token: token || "",
      channels: [],
      credits: 100,
    })
  ).then(() => trackEvent("user_signup"));
}

function updateToken(userId: string, token: string | null): Promise<void> {
  return setDoc(
    new UserDb().doc({
      userId: userId,
    }),
    { token: token || "" },
    { merge: true }
  );
}
