import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="bg-custom-bg text-white font-custom p-8 flex justify-center">
      <div className="max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          This Privacy Policy explains how PENGWIN DESENVOLVIMENTO E APOIO A
          NEGOCIOS LTDA ("we", "our", or "us") collects, uses, and protects your
          information when you use YouGenie and its associated products:
          ThumbGenie, CampaignGenie, and InsightGenie (collectively referred to
          as "Services"). We are committed to protecting your privacy and
          ensuring that your personal information is handled in a safe and
          responsible manner.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          1. Company Information
        </h2>
        <p className="mb-4">
          <strong>Legal Name:</strong>
          <br />
          PENGWIN DESENVOLVIMENTO E APOIO A NEGOCIOS LTDA
        </p>
        <p className="mb-4">
          <strong>Address:</strong>
          <br />
          Rua dos Timbiras, 1925
          <br />
          Sala 903, CEP: 30.140-069
          <br />
          Bairro Lourdes, Belo Horizonte, Minas Gerais
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">2. Data Collection</h2>
        <p className="mb-4">
          <strong>Personal Data Collected:</strong>
          <br />
          We collect your email address through Google sign-in. Additionally,
          via YouTube's API, we collect channel information such as the channel
          title, thumbnail, subscriber count, view count, and video data
          including video titles, view counts, and engagement metrics. We also
          collect and analyze comments on your videos and competitors' videos.
        </p>
        <p className="mb-4">
          <strong>Product-Specific Data Collection:</strong>
          <br />
          - ThumbGenie: We collect and store generated thumbnail images,
          associated prompts, and user preferences for thumbnail generation.
          <br />
          - CampaignGenie: We collect campaign-related data such as campaign
          names, messages, target videos, and performance metrics.
          <br />- InsightGenie: We collect and analyze additional analytics data
          related to your channel and video performance.
        </p>
        <p className="mb-4">
          <strong>Methods of Collection:</strong>
          <br />
          Google sign-in, YouTube's API, user input for each product's specific
          features
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">3. Data Usage</h2>
        <p className="mb-4">
          We use the collected data to:
          <ul className="list-disc list-inside ml-4">
            <li>Provide personalized experiences across all our Services.</li>
            <li>Improve our Services based on user data and feedback.</li>
            <li>Generate AI-powered content and insights.</li>
            <li>
              Track and monitor comments on your and competitors' channels.
            </li>
            <li>Provide analytics and insights about channel performance.</li>
            <li>
              Calculate and display metrics such as Views Per Hour (VPH) and
              outlier scores for videos.
            </li>
            <li>Analyze video comments using AI-powered tools.</li>
            <li>
              Create and manage broadcast campaigns for user engagement
              (CampaignGenie).
            </li>
            <li>
              Generate and store AI-powered thumbnails based on user prompts
              (ThumbGenie).
            </li>
            <li>
              Provide detailed analytics and performance insights
              (InsightGenie).
            </li>
          </ul>
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">4. Data Sharing</h2>
        <p className="mb-4">
          We do not share your personal data with any third parties, including
          AI models. Data collected for one YouGenie product may be used to
          enhance features and experiences across our other products.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">5. User Rights</h2>
        <p className="mb-4">
          <strong>Data Deletion:</strong>
          <br />
          Users can request the deletion of their data by contacting us via
          email at robertovclopes@gmail.com. This will apply to data across all
          YouGenie products.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">6. Data Security</h2>
        <p className="mb-4">
          We employ Google Cloud Platform and adhere to best practices to ensure
          the security of your data across all our Services.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          7. Cookies and Tracking
        </h2>
        <p className="mb-4">
          We do not use cookies or other tracking technologies.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">8. Data Retention</h2>
        <p className="mb-4">
          We retain personal data, channel analytics, and product-specific data
          for as long as the user has an active account with us. Historical data
          may be retained for a period of time to provide trend analysis and
          performance comparisons across all our Services.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          9. Third-Party Links
        </h2>
        <p className="mb-4">
          Our Services include links to YouTube videos and embedded YouTube
          players. These links and embeds are publicly accessible and subject to
          YouTube's own privacy policy.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          10. Children's Privacy
        </h2>
        <p className="mb-4">We do not collect data from children.</p>

        <h2 className="text-xl font-semibold mt-6 mb-2">11. Policy Changes</h2>
        <p className="mb-4">
          We will inform users of any changes to this Privacy Policy via email.
          This policy applies to all YouGenie products: ThumbGenie,
          CampaignGenie, and InsightGenie.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          12. Contact Information
        </h2>
        <p className="mb-4">
          For any questions or concerns regarding your privacy across any of our
          Services, please contact Roberto at robertovclopes@gmail.com.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          13. Compliance with Google API Services User Data Policy
        </h2>
        <p className="mb-4">
          YouGenie's use and transfer to any other app of information received
          from Google APIs will adhere to the{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            className="text-blue-500 underline"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements. This includes the usage of
          YouTube API Services across all our products.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2">
          14. Product-Specific Privacy Information
        </h2>
        <h3 className="text-lg font-semibold mt-4 mb-2">ThumbGenie</h3>
        <p className="mb-4">
          ThumbGenie provides an AI-powered Thumbnail Generator feature. When
          you use this feature, we collect and store the prompts you provide and
          the generated thumbnail images. These thumbnails are associated with
          your account and are used to provide you with a history of your
          generated thumbnails. We do not share these generated thumbnails or
          prompts with third parties. The AI models used for thumbnail
          generation do not store or learn from your personal data or generated
          images.
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">CampaignGenie</h3>
        <p className="mb-4">
          CampaignGenie allows users to create and manage broadcast campaigns to
          engage with their audience across multiple videos. We collect and
          store campaign-related data such as campaign names, messages, target
          videos, and performance metrics. This data is used to execute
          campaigns, track their effectiveness, and provide insights to users.
          Campaign data is treated with the same level of security and privacy
          as other user data and is not shared with third parties.
        </p>

        <h3 className="text-lg font-semibold mt-4 mb-2">InsightGenie</h3>
        <p className="mb-4">
          InsightGenie provides analytics and performance metrics for your
          YouTube channels and videos. This includes subscriber counts, view
          counts, video performance data, and calculated metrics such as Views
          Per Hour (VPH) and outlier scores. The outlier score helps identify
          videos that are performing exceptionally well or poorly compared to
          your channel's average. This data is collected and processed to
          provide you with insights into your channel's performance. We do not
          share this analytics data with third parties.
        </p>

        <p className="mt-6">
          By using any of YouGenie's Services (ThumbGenie, CampaignGenie, or
          InsightGenie), you agree to the terms outlined in this Privacy Policy.
        </p>
      </div>
    </div>
  );
}
