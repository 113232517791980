import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconMenu,
  IconX,
  IconMessageCircleBolt,
  IconChartLine,
  IconSettings,
  IconLogout,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";

export default function PopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/insight-genie");
    setIsOpen(false);
  };

  return (
    <div className="md:hidden relative" style={{ backgroundColor: "#0B0B0F" }}>
      <button onClick={toggleMenu} className="text-white p-4">
        {isOpen ? <IconX size={20} /> : <IconMenu size={20} />}
      </button>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex flex-col items-center py-6 space-y-8 z-50"
          style={{ backgroundColor: "#0B0B0F" }}
        >
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="w-8 h-8 mb-4"
          />
          <button
            onClick={() => handleNavigation("/app/insight-genie")}
            className="flex items-center justify-center"
          >
            <IconMessageCircleBolt
              size={24}
              strokeWidth={1}
              className="text-white"
            />
          </button>
          <button
            onClick={() => handleNavigation("/app/insight-genie/analytics")}
            className="flex items-center justify-center"
          >
            <IconChartLine size={24} strokeWidth={1} className="text-white" />
          </button>
          <button
            onClick={() => handleNavigation("/app/insight-genie/settings")}
            className="flex items-center justify-center"
          >
            <IconSettings size={24} strokeWidth={1} className="text-white" />
          </button>
          <button
            onClick={handleLogout}
            className="flex items-center justify-center"
          >
            <IconLogout size={24} strokeWidth={1} className="text-white" />
          </button>
        </div>
      )}
    </div>
  );
}
