import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/userContext";
import {
  CircularProgress,
  Typography,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { getDocs, collection, addDoc, onSnapshot } from "firebase/firestore";
import { map } from "lodash";
import { IconCoins, IconCreditCard } from "@tabler/icons-react";
import { trackEvent } from "../../../utils/analytics";
import { getFirestore } from "firebase/firestore";
import CustomButton from "../../utils/customButton";

export default function CreditsScreen() {
  const user = useContext(UserContext);
  const [purchasing, setPurchasing] = useState(false);
  const [packages, setPackages] = useState<
    { price: string; quantity: number; amount: number; active: boolean }[]
  >([]);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = getFirestore();
      const pricesCollection = collection(
        db,
        "/products/prod_R34erVq6HwYlZy/prices"
      );
      const snapshot = await getDocs(pricesCollection);
      setPackages(
        map(snapshot.docs, (doc) => ({
          price: doc.id,
          quantity: doc.get("transform_quantity.divide_by"),
          amount: doc.get("unit_amount") / 100,
          active: doc.get("active"),
        })).filter((pkg) => pkg.active) // Filter out inactive packages
      );
    };
    fetchPackages();
  }, []);

  const handlePurchase = async (priceId: string, quantity: number) => {
    if (!user) return;
    setPurchasing(true);
    trackEvent("purchase_credits", { priceId, quantity });

    const db = getFirestore();
    const sessionRef = await addDoc(
      collection(db, `users/${user.id}/checkout_sessions`),
      {
        price: priceId,
        quantity: 1,
        payment_method_types: ["card"],
        mode: "payment",
        billing_address_collection: "required",
        allow_promotion_codes: true,
        success_url: window.location.origin + "/app/thumb-genie/credits",
        cancel_url: window.location.href,
        customer_update: { name: "auto" },
        collect_tax_ids: true,
        metadata: {
          userId: user.id,
          amount: quantity.toString(),
        },
      }
    );

    onSnapshot(sessionRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        setPurchasing(false);
        alert(`An error occurred: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress color="inherit" size={40} />
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-[#0B0B0F] text-white p-2 md:px-32 md:py-8 font-custom">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-custom font-bold">ThumbGenie Credits</h4>
      </div>
      <Divider
        sx={{
          borderColor: "#282828",
          borderBottomWidth: 2,
          marginBottom: 4,
        }}
      />

      {/* Balance Card */}
      <div className="bg-[#1E1E1E] px-6 py-4 rounded-lg flex items-center space-x-4 mb-6">
        <IconCoins size={30} className="text-[#FFD700]" />
        <div>
          <p className="text-sm text-gray-400">Balance</p>
          <p className="text-lg font-bold">
            {(user.credits || 0).toLocaleString()} credits
          </p>
        </div>
      </div>

      {/* Credit Packages */}
      <Grid container spacing={3}>
        {packages.map((pkg) => (
          <Grid item xs={12} sm={6} md={3} key={pkg.price}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: "16px",
                background: "linear-gradient(135deg, #FF8A00 0%, #E52E71 100%)",
                color: "#FFFFFF",
                height: "100%",
              }}
            >
              <div className="flex flex-col h-full justify-between">
                <div>
                  <Typography variant="h6" className="font-custom mb-1">
                    {pkg.quantity.toLocaleString()} credits
                  </Typography>
                  <Typography
                    variant="body2"
                    className="font-custom text-gray-200"
                    sx={{ marginBottom: "0.5rem" }}
                  >
                    for ThumbGenie
                  </Typography>
                  <Typography variant="h5" className="font-custom mb-1">
                    ${pkg.amount.toFixed(2)}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="font-custom text-gray-200"
                    sx={{ marginBottom: "0.5rem" }}
                  >
                    ${(pkg.amount / pkg.quantity).toFixed(2)} per thumbnail
                  </Typography>
                </div>
                <CustomButton
                  onClick={() => handlePurchase(pkg.price, pkg.quantity)}
                  disabled={purchasing}
                  startIcon={<IconCreditCard size={20} />}
                  sx={{
                    mt: 4,
                    backgroundColor: "#FFFFFF",
                    color: "#E52E71",
                    fontFamily: "'YouTubeSansRegular', sans-serif",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  }}
                >
                  {purchasing ? "Processing..." : "Purchase"}
                </CustomButton>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
