import BaseDb from "./baseDb";
import { Transaction } from "../models/transaction";
import { FirestoreDataConverter } from "firebase/firestore";
import moment from "moment";
import { omit } from "lodash";
import toFirestore from "./toFirestore";

export default class TransactionDb extends BaseDb<
  Transaction,
  { userId: string; campaignId: string; transactionId: string }
> {
  _path: string =
    "users/userId/campaigns/campaignId/transactions/transactionId";

  getConverter(): FirestoreDataConverter<Transaction> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new Transaction({
          id: snapshot.id,
          amount: data.amount,
          currency: data.currency,
          campaignId: data.campaignId,
          videoId: data.videoId,
          linkId: data.linkId,
          timestamp: moment(data.timestamp.toDate()),
        });
      },
      toFirestore: (transaction) => {
        return toFirestore(omit(transaction, ["id"]));
      },
    };
  }
}
